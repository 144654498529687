@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
aio-shell.page-home {
  /* string  - pink */
  /* comment - skyblue */
  /* type    - lightgreen */
  /* literal - darkred */
  /* punctuation */
  /* plaintext */
  /* html/xml tag    - lightyellow */
  /* attribute name  - khaki */
  /* attribute value - pink */
  /* decimal         - lightgreen */
  /* Specify class=linenums on a pre to get line numbering */
  /* IE indents via margin-left */
  /* Alternate shading for lines */
}
aio-shell.page-home .prettyprint-scroller {
  overflow: auto;
  display: block;
  max-width: 100%;
}
aio-shell.page-home pre.prettyprint {
  border: none;
  padding: 1rem 2rem;
  margin: 0;
  border-radius: 3px;
  font-family: "PT Mono", monospace;
  font-weight: normal;
  font-size: 13px;
  line-height: 1.6em;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.3);
}
@media screen and (max-width: 600px) {
  aio-shell.page-home pre.prettyprint {
    min-width: 460px;
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    @media screen and (max-width: 600px) {
      aio-shell.page-home pre.prettyprint {
        min-width: 680px;
      }
    }
  }
}
aio-shell.page-home pre .nocode {
  background-color: none;
  color: #000;
}
aio-shell.page-home pre .str {
  color: #eac3f1;
}
aio-shell.page-home pre .kwd {
  color: #f0e68c;
  font-weight: bold;
}
aio-shell.page-home pre .com {
  color: #87ceeb;
}
aio-shell.page-home pre .typ {
  color: #fec576;
}
aio-shell.page-home pre .lit {
  color: #cd72dd;
}
aio-shell.page-home pre .pun {
  color: #fff;
}
aio-shell.page-home pre .pln {
  color: #fff;
}
aio-shell.page-home pre .tag {
  color: #f0e68c;
  font-weight: bold;
}
aio-shell.page-home pre .atn {
  color: #bdb76b;
  font-weight: bold;
}
aio-shell.page-home pre .atv {
  color: #eac3f1;
}
aio-shell.page-home pre .dec {
  color: #fec576;
}
aio-shell.page-home ol.linenums {
  margin-top: 0;
  margin-bottom: 0;
  color: #aeaeae;
}
aio-shell.page-home li.L0,
aio-shell.page-home li.L1,
aio-shell.page-home li.L2,
aio-shell.page-home li.L3,
aio-shell.page-home li.L5,
aio-shell.page-home li.L6,
aio-shell.page-home li.L7,
aio-shell.page-home li.L8 {
  list-style-type: none;
}
ngrx-circles circle {
  fill: white;
}
ngrx-circles[color=primary] circle {
  fill: #b836cf;
}
ngrx-circles[color=accent] circle {
  fill: #fdac3b;
}
ngrx-circles g:nth-of-type(1) {
  transform: translate(100px, 100px) rotate(0deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(2) {
  transform: translate(100px, 100px) rotate(20deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(3) {
  transform: translate(100px, 100px) rotate(40deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(4) {
  transform: translate(100px, 100px) rotate(60deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(5) {
  transform: translate(100px, 100px) rotate(80deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(6) {
  transform: translate(100px, 100px) rotate(100deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(7) {
  transform: translate(100px, 100px) rotate(120deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(8) {
  transform: translate(100px, 100px) rotate(140deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(9) {
  transform: translate(100px, 100px) rotate(160deg) translate(-100px, -100px) translate(95px, 5px);
}
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}
.mat-small, .mat-caption {
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}
.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}
.mat-card-header .mat-card-title {
  font-size: 20px;
}
.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}
.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}
.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.mat-cell, .mat-footer-cell {
  font-size: 14px;
}
.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-calendar-body {
  font-size: 13px;
}
.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}
.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.mat-expansion-panel-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.mat-form-field-label {
  top: 1.34375em;
}
.mat-form-field-underline {
  bottom: 1.34375em;
}
.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}
.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
input.mat-input-element {
  margin-top: -0.0625em;
}
.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}
.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-select-trigger {
  height: 1.125em;
}
.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}
.mat-step-sub-label-error {
  font-weight: normal;
}
.mat-step-label-error {
  font-size: 14px;
}
.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}
.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}
.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}
.mat-optgroup-label {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}
.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}
.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}
.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}
.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}
.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
@-webkit-keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@-webkit-keyframes cdk-text-field-autofill-end {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  -webkit-animation: cdk-text-field-autofill-start 0s 1ms;
          animation: cdk-text-field-autofill-start 0s 1ms;
}
.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  -webkit-animation: cdk-text-field-autofill-end 0s 1ms;
          animation: cdk-text-field-autofill-end 0s 1ms;
}
textarea.cdk-textarea-autosize {
  resize: none;
}
textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
.mat-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator {
  position: relative;
}
.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #a829c3;
}
.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #fc9b2d;
}
.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}
.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #a829c3;
}
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #fc9b2d;
}
.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}
.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.mat-badge-content {
  color: white;
  background: #a829c3;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}
.mat-badge-accent .mat-badge-content {
  background: #fc9b2d;
  color: black;
}
.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.mat-badge {
  position: relative;
}
.mat-badge-hidden .mat-badge-content {
  display: none;
}
.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}
.mat-badge-content.mat-badge-active {
  transform: none;
}
.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #a829c3;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #fc9b2d;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #a829c3;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #fc9b2d;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.mat-button-focus-overlay {
  background: black;
}
.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: black;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #a829c3;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #fc9b2d;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}
[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}
.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.mat-checkbox-checkmark {
  fill: #fafafa;
}
.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #a829c3;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #fc9b2d;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-checkbox .mat-ripple-element {
  background-color: black;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #a829c3;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #fc9b2d;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #a829c3;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #fc9b2d;
  color: black;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: black;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-table {
  background: white;
}
.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}
mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}
.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}
.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.mat-calendar-body-in-range::before {
  background: rgba(168, 41, 195, 0.2);
}
.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(168, 41, 195, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(168, 41, 195, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-calendar-body-selected {
  background-color: #a829c3;
  color: white;
}
.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(168, 41, 195, 0.4);
}
.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(252, 155, 45, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(252, 155, 45, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(252, 155, 45, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #fc9b2d;
  color: black;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(252, 155, 45, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px black;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-datepicker-toggle-active {
  color: #a829c3;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #fc9b2d;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}
.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.mat-form-field.mat-focused .mat-form-field-label {
  color: #a829c3;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #fc9b2d;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.mat-focused .mat-form-field-required-marker {
  color: #fc9b2d;
}
.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #a829c3;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #fc9b2d;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #a829c3;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #fc9b2d;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.mat-error {
  color: #f44336;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #a829c3;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #fc9b2d;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.mat-icon.mat-primary {
  color: #a829c3;
}
.mat-icon.mat-accent {
  color: #fc9b2d;
}
.mat-icon.mat-warn {
  color: #f44336;
}
.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.mat-input-element {
  caret-color: #a829c3;
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-form-field.mat-accent .mat-input-element {
  caret-color: #fc9b2d;
}
.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-item-disabled {
  background-color: #eeeeee;
}
.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled], .mat-menu-item[disabled]::after,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-paginator {
  background: white;
}
.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-paginator-container {
  min-height: 56px;
}
.mat-progress-bar-background {
  fill: #b130ca;
}
.mat-progress-bar-buffer {
  background-color: #b130ca;
}
.mat-progress-bar-fill::after {
  background-color: #a829c3;
}
.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fda535;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fda535;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #fc9b2d;
}
.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #a829c3;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #fc9b2d;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #a829c3;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #a829c3;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #fc9b2d;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #fc9b2d;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}
.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #a829c3;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #fc9b2d;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #fc9b2d;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(252, 155, 45, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #fc9b2d;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #a829c3;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(168, 41, 195, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #a829c3;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #a829c3;
}
.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-primary .mat-slider-focus-ring {
  background-color: rgba(168, 41, 195, 0.2);
}
.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #fc9b2d;
}
.mat-accent .mat-slider-thumb-label-text {
  color: black;
}
.mat-accent .mat-slider-focus-ring {
  background-color: rgba(252, 155, 45, 0.2);
}
.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #a829c3;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: black;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #fc9b2d;
  color: black;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}
.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.mat-horizontal-stepper-header {
  height: 72px;
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}
.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}
.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}
.mat-sort-header-arrow {
  color: #757575;
}
.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(177, 48, 202, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #a829c3;
}
.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(253, 165, 53, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #fc9b2d;
}
.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: black;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(177, 48, 202, 0.3);
}
.mat-tab-group.mat-background-primary .mat-tab-header, .mat-tab-group.mat-background-primary .mat-tab-links, .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #a829c3;
}
.mat-tab-group.mat-background-primary .mat-tab-label, .mat-tab-group.mat-background-primary .mat-tab-link, .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(253, 165, 53, 0.3);
}
.mat-tab-group.mat-background-accent .mat-tab-header, .mat-tab-group.mat-background-accent .mat-tab-links, .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #fc9b2d;
}
.mat-tab-group.mat-background-accent .mat-tab-label, .mat-tab-group.mat-background-accent .mat-tab-link, .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: black;
}
.mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: black;
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.4);
}
.mat-tab-group.mat-background-accent .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn .mat-tab-header, .mat-tab-group.mat-background-warn .mat-tab-links, .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn .mat-tab-label, .mat-tab-group.mat-background-warn .mat-tab-link, .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #a829c3;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #fc9b2d;
  color: black;
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.mat-toolbar-multiple-rows {
  min-height: 64px;
}
.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}
@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.mat-tree {
  background: white;
}
.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tree-node {
  min-height: 48px;
}
.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-simple-snackbar-action {
  color: #fc9b2d;
}
/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
aio-shell.page-home {
  /* string  - pink */
  /* comment - skyblue */
  /* type    - lightgreen */
  /* literal - darkred */
  /* punctuation */
  /* plaintext */
  /* html/xml tag    - lightyellow */
  /* attribute name  - khaki */
  /* attribute value - pink */
  /* decimal         - lightgreen */
  /* Specify class=linenums on a pre to get line numbering */
  /* IE indents via margin-left */
  /* Alternate shading for lines */
}
aio-shell.page-home .prettyprint-scroller {
  overflow: auto;
  display: block;
  max-width: 100%;
}
aio-shell.page-home pre.prettyprint {
  border: none;
  padding: 1rem 2rem;
  margin: 0;
  border-radius: 3px;
  font-family: "PT Mono", monospace;
  font-weight: normal;
  font-size: 13px;
  line-height: 1.6em;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.3);
}
@media screen and (max-width: 600px) {
  aio-shell.page-home pre.prettyprint {
    min-width: 460px;
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    @media screen and (max-width: 600px) {
      aio-shell.page-home pre.prettyprint {
        min-width: 680px;
      }
    }
  }
}
aio-shell.page-home pre .nocode {
  background-color: none;
  color: #000;
}
aio-shell.page-home pre .str {
  color: #eac3f1;
}
aio-shell.page-home pre .kwd {
  color: #f0e68c;
  font-weight: bold;
}
aio-shell.page-home pre .com {
  color: #87ceeb;
}
aio-shell.page-home pre .typ {
  color: #fec576;
}
aio-shell.page-home pre .lit {
  color: #cd72dd;
}
aio-shell.page-home pre .pun {
  color: #fff;
}
aio-shell.page-home pre .pln {
  color: #fff;
}
aio-shell.page-home pre .tag {
  color: #f0e68c;
  font-weight: bold;
}
aio-shell.page-home pre .atn {
  color: #bdb76b;
  font-weight: bold;
}
aio-shell.page-home pre .atv {
  color: #eac3f1;
}
aio-shell.page-home pre .dec {
  color: #fec576;
}
aio-shell.page-home ol.linenums {
  margin-top: 0;
  margin-bottom: 0;
  color: #aeaeae;
}
aio-shell.page-home li.L0,
aio-shell.page-home li.L1,
aio-shell.page-home li.L2,
aio-shell.page-home li.L3,
aio-shell.page-home li.L5,
aio-shell.page-home li.L6,
aio-shell.page-home li.L7,
aio-shell.page-home li.L8 {
  list-style-type: none;
}
ngrx-circles circle {
  fill: white;
}
ngrx-circles[color=primary] circle {
  fill: #b836cf;
}
ngrx-circles[color=accent] circle {
  fill: #fdac3b;
}
ngrx-circles g:nth-of-type(1) {
  transform: translate(100px, 100px) rotate(0deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(2) {
  transform: translate(100px, 100px) rotate(20deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(3) {
  transform: translate(100px, 100px) rotate(40deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(4) {
  transform: translate(100px, 100px) rotate(60deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(5) {
  transform: translate(100px, 100px) rotate(80deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(6) {
  transform: translate(100px, 100px) rotate(100deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(7) {
  transform: translate(100px, 100px) rotate(120deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(8) {
  transform: translate(100px, 100px) rotate(140deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(9) {
  transform: translate(100px, 100px) rotate(160deg) translate(-100px, -100px) translate(95px, 5px);
}
/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
aio-shell.page-home {
  /* string  - pink */
  /* comment - skyblue */
  /* type    - lightgreen */
  /* literal - darkred */
  /* punctuation */
  /* plaintext */
  /* html/xml tag    - lightyellow */
  /* attribute name  - khaki */
  /* attribute value - pink */
  /* decimal         - lightgreen */
  /* Specify class=linenums on a pre to get line numbering */
  /* IE indents via margin-left */
  /* Alternate shading for lines */
}
aio-shell.page-home .prettyprint-scroller {
  overflow: auto;
  display: block;
  max-width: 100%;
}
aio-shell.page-home pre.prettyprint {
  border: none;
  padding: 1rem 2rem;
  margin: 0;
  border-radius: 3px;
  font-family: "PT Mono", monospace;
  font-weight: normal;
  font-size: 13px;
  line-height: 1.6em;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.3);
}
@media screen and (max-width: 600px) {
  aio-shell.page-home pre.prettyprint {
    min-width: 460px;
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    @media screen and (max-width: 600px) {
      aio-shell.page-home pre.prettyprint {
        min-width: 680px;
      }
    }
  }
}
aio-shell.page-home pre .nocode {
  background-color: none;
  color: #000;
}
aio-shell.page-home pre .str {
  color: #eac3f1;
}
aio-shell.page-home pre .kwd {
  color: #f0e68c;
  font-weight: bold;
}
aio-shell.page-home pre .com {
  color: #87ceeb;
}
aio-shell.page-home pre .typ {
  color: #fec576;
}
aio-shell.page-home pre .lit {
  color: #cd72dd;
}
aio-shell.page-home pre .pun {
  color: #fff;
}
aio-shell.page-home pre .pln {
  color: #fff;
}
aio-shell.page-home pre .tag {
  color: #f0e68c;
  font-weight: bold;
}
aio-shell.page-home pre .atn {
  color: #bdb76b;
  font-weight: bold;
}
aio-shell.page-home pre .atv {
  color: #eac3f1;
}
aio-shell.page-home pre .dec {
  color: #fec576;
}
aio-shell.page-home ol.linenums {
  margin-top: 0;
  margin-bottom: 0;
  color: #aeaeae;
}
aio-shell.page-home li.L0,
aio-shell.page-home li.L1,
aio-shell.page-home li.L2,
aio-shell.page-home li.L3,
aio-shell.page-home li.L5,
aio-shell.page-home li.L6,
aio-shell.page-home li.L7,
aio-shell.page-home li.L8 {
  list-style-type: none;
}
ngrx-circles circle {
  fill: white;
}
ngrx-circles[color=primary] circle {
  fill: #b836cf;
}
ngrx-circles[color=accent] circle {
  fill: #fdac3b;
}
ngrx-circles g:nth-of-type(1) {
  transform: translate(100px, 100px) rotate(0deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(2) {
  transform: translate(100px, 100px) rotate(20deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(3) {
  transform: translate(100px, 100px) rotate(40deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(4) {
  transform: translate(100px, 100px) rotate(60deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(5) {
  transform: translate(100px, 100px) rotate(80deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(6) {
  transform: translate(100px, 100px) rotate(100deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(7) {
  transform: translate(100px, 100px) rotate(120deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(8) {
  transform: translate(100px, 100px) rotate(140deg) translate(-100px, -100px) translate(95px, 5px);
}
ngrx-circles g:nth-of-type(9) {
  transform: translate(100px, 100px) rotate(160deg) translate(-100px, -100px) translate(95px, 5px);
}
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-badge-small .mat-badge-content {
  font-size: 9px;
}
.mat-badge-large .mat-badge-content {
  font-size: 24px;
}
.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}
.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}
.mat-small, .mat-caption {
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}
.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}
.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}
.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}
.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}
.mat-card-header .mat-card-title {
  font-size: 20px;
}
.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}
.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}
.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}
.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}
.mat-cell, .mat-footer-cell {
  font-size: 14px;
}
.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-calendar-body {
  font-size: 13px;
}
.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}
.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}
.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.mat-expansion-panel-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}
.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}
.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}
.mat-form-field-label {
  top: 1.34375em;
}
.mat-form-field-underline {
  bottom: 1.34375em;
}
.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}
@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}
.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}
input.mat-input-element {
  margin-top: -0.0625em;
}
.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}
.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-select-trigger {
  height: 1.125em;
}
.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}
.mat-step-sub-label-error {
  font-weight: normal;
}
.mat-step-label-error {
  font-size: 14px;
}
.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}
.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}
.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}
.mat-optgroup-label {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}
.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}
.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}
.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}
.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}
.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  -webkit-animation: cdk-text-field-autofill-start 0s 1ms;
          animation: cdk-text-field-autofill-start 0s 1ms;
}
.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  -webkit-animation: cdk-text-field-autofill-end 0s 1ms;
          animation: cdk-text-field-autofill-end 0s 1ms;
}
textarea.cdk-textarea-autosize {
  resize: none;
}
textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
.mat-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator {
  position: relative;
}
.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #a829c3;
}
.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #fc9b2d;
}
.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}
.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #a829c3;
}
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #fc9b2d;
}
.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}
.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.mat-badge-content {
  color: white;
  background: #a829c3;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}
.mat-badge-accent .mat-badge-content {
  background: #fc9b2d;
  color: black;
}
.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.mat-badge {
  position: relative;
}
.mat-badge-hidden .mat-badge-content {
  display: none;
}
.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}
.mat-badge-content.mat-badge-active {
  transform: none;
}
.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #a829c3;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #fc9b2d;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #a829c3;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #fc9b2d;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.mat-button-focus-overlay {
  background: black;
}
.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: black;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #a829c3;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #fc9b2d;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}
[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.mat-checkbox-checkmark {
  fill: #fafafa;
}
.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #a829c3;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #fc9b2d;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-checkbox .mat-ripple-element {
  background-color: black;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #a829c3;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #fc9b2d;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #a829c3;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #fc9b2d;
  color: black;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: black;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-table {
  background: white;
}
.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}
mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}
.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}
.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.mat-calendar-body-in-range::before {
  background: rgba(168, 41, 195, 0.2);
}
.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(168, 41, 195, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(168, 41, 195, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-calendar-body-selected {
  background-color: #a829c3;
  color: white;
}
.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(168, 41, 195, 0.4);
}
.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(252, 155, 45, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(252, 155, 45, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(252, 155, 45, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #fc9b2d;
  color: black;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(252, 155, 45, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px black;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-datepicker-toggle-active {
  color: #a829c3;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #fc9b2d;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.mat-form-field.mat-focused .mat-form-field-label {
  color: #a829c3;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #fc9b2d;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.mat-focused .mat-form-field-required-marker {
  color: #fc9b2d;
}
.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #a829c3;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #fc9b2d;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #a829c3;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #fc9b2d;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.mat-error {
  color: #f44336;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #a829c3;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #fc9b2d;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.mat-icon.mat-primary {
  color: #a829c3;
}
.mat-icon.mat-accent {
  color: #fc9b2d;
}
.mat-icon.mat-warn {
  color: #f44336;
}
.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.mat-input-element {
  caret-color: #a829c3;
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-form-field.mat-accent .mat-input-element {
  caret-color: #fc9b2d;
}
.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-item-disabled {
  background-color: #eeeeee;
}
.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled], .mat-menu-item[disabled]::after,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-paginator {
  background: white;
}
.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-progress-bar-background {
  fill: #b130ca;
}
.mat-progress-bar-buffer {
  background-color: #b130ca;
}
.mat-progress-bar-fill::after {
  background-color: #a829c3;
}
.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fda535;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fda535;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #fc9b2d;
}
.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #a829c3;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #fc9b2d;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #a829c3;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #a829c3;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #fc9b2d;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #fc9b2d;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}
.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #a829c3;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #fc9b2d;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #fc9b2d;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(252, 155, 45, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #fc9b2d;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #a829c3;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(168, 41, 195, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #a829c3;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #a829c3;
}
.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-primary .mat-slider-focus-ring {
  background-color: rgba(168, 41, 195, 0.2);
}
.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #fc9b2d;
}
.mat-accent .mat-slider-thumb-label-text {
  color: black;
}
.mat-accent .mat-slider-focus-ring {
  background-color: rgba(252, 155, 45, 0.2);
}
.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #a829c3;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: black;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #fc9b2d;
  color: black;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}
.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.mat-sort-header-arrow {
  color: #757575;
}
.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(177, 48, 202, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #a829c3;
}
.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(253, 165, 53, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #fc9b2d;
}
.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: black;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(177, 48, 202, 0.3);
}
.mat-tab-group.mat-background-primary .mat-tab-header, .mat-tab-group.mat-background-primary .mat-tab-links, .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #a829c3;
}
.mat-tab-group.mat-background-primary .mat-tab-label, .mat-tab-group.mat-background-primary .mat-tab-link, .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(253, 165, 53, 0.3);
}
.mat-tab-group.mat-background-accent .mat-tab-header, .mat-tab-group.mat-background-accent .mat-tab-links, .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #fc9b2d;
}
.mat-tab-group.mat-background-accent .mat-tab-label, .mat-tab-group.mat-background-accent .mat-tab-link, .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: black;
}
.mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: black;
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.4);
}
.mat-tab-group.mat-background-accent .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn .mat-tab-header, .mat-tab-group.mat-background-warn .mat-tab-links, .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn .mat-tab-label, .mat-tab-group.mat-background-warn .mat-tab-link, .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #a829c3;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #fc9b2d;
  color: black;
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.mat-tree {
  background: white;
}
.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-simple-snackbar-action {
  color: #fc9b2d;
}
/* ==============================
   BASE STYLES
   ============================== */
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
body {
  font-size: 16px;
  line-height: 24px;
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  margin: 0;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  display: inline-block;
  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
  margin: 8px 0px;
}
@media screen and (max-width: 600px) {
  h1 {
    margin-top: 0;
  }
}
h2 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  margin: 32px 0px 24px;
  clear: both;
}
h3 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  margin: 24px 0px 12px;
  clear: both;
}
h4 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin: 8px 0px;
  clear: both;
}
h5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 8px 0px;
  clear: both;
}
h6 {
  color: #6e6e6e;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 8px 0px;
  clear: both;
}
h2 a, h3 a, h4 a, h5 a, h6 a {
  font-size: inherit;
}
@media screen and (max-width: 600px) {
  h2, h3, h4, h5, h6 {
    margin: 8px 0;
  }
}
.mat-tab-body-wrapper h2 {
  margin-top: 0;
}
p, ol, ul, ol, li, input, a {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  font-weight: 400;
}
p > em, ol > em, ul > em, ol > em, li > em, input > em, a > em {
  letter-spacing: 0.3px;
}
ol li, ol p {
  margin: 4px 0;
}
li p {
  margin: 0;
}
a {
  text-decoration: none;
}
.app-toolbar a {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  color: #FFFFFF;
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  text-transform: uppercase;
  padding: 21px 0;
}
aio-shell.page-home .app-toolbar a {
  color: #412846;
}
strong {
  font-weight: 600;
}
table {
  border-collapse: collapse;
  border-radius: 2px;
  border-spacing: 0;
  margin: 12px 0 32px;
}
table tbody th {
  max-width: 100px;
  padding: 13px 32px;
  text-align: left;
}
td {
  font-weight: 400;
  padding: 8px 30px;
  letter-spacing: 0.3px;
}
td p {
  margin: 0;
}
th {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 13px 32px;
  text-align: left;
}
p > code, li > code, td > code, th > code {
  font-family: "Droid Sans Mono", monospace;
  font-size: 85%;
  color: #333;
  letter-spacing: 0;
  line-height: 1;
  padding: 2px 0;
  background-color: #F1F1F1;
  border-radius: 4px;
}
code {
  font-family: "Droid Sans Mono", monospace;
  font-size: 90%;
}
.sidenav-content a {
  color: #84438a;
}
.sidenav-content a:hover {
  color: #6e6e6e;
}
/* ==============================
   LAYOUT STYLES
   ============================== */
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
.api-body {
  max-width: 1200px;
}
.api-body table {
  margin: 12px 0 24px;
}
.api-body table th {
  text-transform: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}
.api-body table tr {
  border-bottom: 1px solid #DBDBDB;
}
.api-body table td {
  vertical-align: middle;
}
.api-body table hr {
  margin: 16px 0;
}
.api-body table tr:last-child {
  border-bottom: none;
}
.api-body table.item-table td {
  padding: 32px;
}
.api-body table.list-table td {
  padding: 16px 24px;
}
.api-body table .short-description {
  margin-left: 0;
}
aio-shell.page-docs .sidenav-content {
  margin: auto;
}
.sidenav-content {
  min-height: 100vh;
  padding: 80px 3rem 1rem;
}
@media (max-width: 600px) {
  aio-menu {
    display: none;
  }

  .sidenav-content {
    min-height: 450px;
    padding: 80px 1rem 1rem;
  }
}
.sidenav-container {
  width: 100%;
  height: 100vh;
}
.sidenav-content button {
  min-width: 24px;
}
#guide-change-log h2::before {
  content: "";
  display: block;
  height: 1px;
  margin: 24px 0px;
  background: #DBDBDB;
}
.no-animations aio-doc-viewer > * {
  transition: none !important;
}
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
footer {
  position: relative;
  line-height: 24px;
  flex: 1;
  padding: 48px;
  z-index: 0;
  background-color: #412846;
  color: #FAFAFA;
  font-weight: 300;
}
footer aio-footer {
  position: relative;
  z-index: 0;
}
footer .footer-block {
  margin: 0 24px;
  vertical-align: top;
}
footer a {
  color: #FAFAFA;
  font-weight: 300;
  text-decoration: none;
  z-index: 20;
  position: relative;
}
footer a:hover {
  text-decoration: underline;
}
footer a:visited {
  text-decoration: none;
}
footer a.action {
  cursor: pointer;
}
footer h3 {
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 400;
  margin: 0 0 16px;
}
footer p {
  text-align: center;
  margin: 10px 0px 5px;
}
@media (max-width: 480px) {
  footer p {
    text-align: left;
  }
}
footer div.grid-fluid {
  display: flex;
  justify-content: center;
  text-align: left;
  margin: 0 0 40px;
}
footer div.grid-fluid ul {
  list-style-position: inside;
  padding: 0px;
  margin: 0px;
}
footer div.grid-fluid ul li {
  list-style-type: none;
  padding: 0px;
  text-align: left;
}
@media (max-width: 480px) {
  footer div.grid-fluid {
    flex-direction: column;
  }
  footer div.grid-fluid .footer-block {
    margin: 8px 24px;
  }
}
@media (max-width: 700px) {
  footer h3 {
    font-size: 20px;
    line-height: 28px;
  }
}
@media (max-width: 600px) {
  footer h3 {
    font-size: 16px;
    line-height: 24px;
  }
}
footer::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
html, body {
  height: 100%;
}
body {
  background-color: #FAFAFA;
}
.clearfix {
  content: "";
  display: table;
  clear: both;
}
.clear {
  clear: both;
}
.l-clearfix:after, .clearfix:after {
  content: "";
  display: table;
  clear: both;
}
.is-visible {
  display: block !important;
}
.l-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.center {
  text-align: center;
}
.visually-hidden {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}
.text-uppercase {
  text-transform: uppercase;
}
.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  min-height: 480px;
  height: 80vh;
  max-height: 480px;
  box-sizing: border-box;
  padding: 48px 48px 32px 48px;
  overflow: hidden;
  transform-origin: 100%;
}
@media (max-width: 480px) {
  .hero {
    max-height: 486px;
    padding-top: 40px;
    transform: none;
  }
}
.hero .hero-title {
  display: inline-block;
  font-size: 28px;
  font-weight: 400;
  line-height: 48px;
  margin: 0 8px 0 0;
  text-transform: uppercase;
}
.hero .hero-title.is-standard-case {
  text-transform: none;
}
.homepage-header {
  background: url("/assets/images/banner.svg");
  background-size: 100%;
}
section#intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 900px;
  height: 480px;
  margin: 0 auto -32px;
  padding: 48px 0 0;
  color: white;
}
@media (max-width: 780px) {
  section#intro {
    justify-content: center;
    width: 100%;
    max-width: 100vw;
    padding: 40px 0 32px;
  }
  section#intro button {
    margin: 0;
    height: 60px;
  }
}
section#intro .homepage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
  margin-top: -7%;
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 780px) {
  section#intro .homepage-container {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
}
section#intro .hero-headline {
  font-size: 40px;
  line-height: 64px;
  font-weight: 500;
  margin: 32px 0 8px 0;
}
section#intro .hero-headline:after {
  display: none;
}
@media (max-width: 780px) {
  section#intro .hero-headline {
    text-align: center;
  }
}
@media (max-width: 575px) {
  section#intro .hero-headline {
    font-size: 32px;
    line-height: 50px;
  }
}
section#intro .hero-sub-headline {
  font-size: 18px;
  line-height: 32px;
}
section#intro .hero-logo {
  display: flex;
  padding-top: 48px;
  padding-bottom: 24px;
}
@media (max-width: 780px) {
  section#intro .hero-logo {
    justify-content: center;
  }
}
section#intro .hero-logo img {
  width: 260px;
  height: 260px;
  margin-bottom: 8px;
  padding: 0;
  filter: drop-shadow(0 2px 2px rgba(10, 16, 20, 0.24));
}
@media (max-width: 780px) {
  section#intro .hero-logo img {
    width: 250px;
    height: 250px;
  }
}
.announcement-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  max-width: 50vw;
  margin: 0 auto;
  padding: 16px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
  box-sizing: border-box;
  transition: all 0.3s ease-in;
}
@media (max-width: 992px) {
  .announcement-bar {
    flex-direction: column;
    text-align: center;
    padding: 32px 16px;
  }
}
@media (max-width: 768px) {
  .announcement-bar {
    width: 100%;
    max-width: none;
  }
}
.announcement-bar > * {
  margin: 8px;
}
.announcement-bar .button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-width: 160px;
  font-size: 16px;
  color: #FFFFFF;
  background-color: #412846;
  border-radius: 48px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  box-sizing: border-box;
  cursor: pointer;
}
.announcement-bar .button:hover {
  color: rgba(255, 255, 255, 0.7);
}
.announcement-bar .material-icons {
  display: none;
  right: 0;
  position: static;
  transition: all 0.3s ease-in;
  font-size: 16px;
}
.announcement-bar p {
  font-size: 16px;
  margin: 8px;
  text-align: center;
}
.background-sky {
  color: #FFFFFF;
}
.home-row .card {
  height: 70%;
  width: auto;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
  box-sizing: border-box;
  transition: box-shadow 0.5s;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 70%;
  min-width: 350px;
  height: auto;
  margin: auto;
  padding: 24px;
  box-shadow: 0 6px 6px rgba(10, 16, 20, 0.15), 0 0 52px rgba(10, 16, 20, 0.12);
}
.home-row .card:hover {
  box-shadow: 0 8px 8px rgba(10, 16, 20, 0.24), 0 0 8px rgba(10, 16, 20, 0.12);
  text-decoration: none;
}
@media (max-width: 600px) {
  .home-row .card {
    margin: 16px auto 0;
  }
  .home-row .card h2 {
    margin: 0;
  }
  .home-row .card img {
    max-width: none;
    height: 70px;
  }
}
@media (max-width: 1300px) {
  .home-row .card img {
    height: 70px;
    max-width: none;
  }
}
.home-row .card img {
  margin: 16px;
}
.home-row .card .card-text-container {
  margin: 0 16px;
}
.home-row .card .card-text-container p {
  text-align: left;
  color: #333;
  margin: 0;
  padding: 8px 0;
}
.home-row .card:hover h2 {
  color: #412846;
}
.button.hero-cta {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 184px;
  height: 40px;
  padding: 0 24px;
  font-size: 18px;
  font-weight: 600;
  line-height: 40px;
  background-color: #FFFFFF;
  border-radius: 48px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  box-sizing: border-box;
  cursor: pointer;
}
.button.hero-cta:hover {
  opacity: 0.9;
}
aio-shell.page-resources section, aio-shell.page-events section, aio-shell.page-features section, aio-shell.page-contribute section {
  padding: 0rem 0rem 3rem;
}
aio-shell.page-home article {
  color: #FFFFFF;
  background-color: #412846;
}
aio-shell.page-home section {
  padding: 0;
}
aio-shell.page-home, aio-shell.page-resources, aio-shell.page-events, aio-shell.page-contribute {
  color: #FFFFFF;
}
aio-shell.page-home article, aio-shell.page-resources article, aio-shell.page-events article, aio-shell.page-contribute article {
  padding: 32px;
}
@media (max-width: 800px) {
  aio-shell.page-home article, aio-shell.page-resources article, aio-shell.page-events article, aio-shell.page-contribute article {
    padding: 24px;
  }
}
aio-shell.page-features article {
  padding: 0 3rem;
}
@media (max-width: 1300px) {
  aio-shell.page-home .content img, aio-shell.page-resources .content img, aio-shell.page-events .content img, aio-shell.page-features .content img {
    max-width: none;
  }
}
aio-shell.page-home .feature-section img, aio-shell.page-resources .feature-section img, aio-shell.page-events .feature-section img, aio-shell.page-features .feature-section img {
  max-width: 70px;
}
@media (max-width: 600px) {
  aio-shell.page-home mat-sidenav-container.sidenav-container, aio-shell.page-resources mat-sidenav-container.sidenav-container, aio-shell.page-events mat-sidenav-container.sidenav-container, aio-shell.page-features mat-sidenav-container.sidenav-container {
    padding-top: 0;
  }
}
aio-shell .cta-bar .hero-cta {
  color: #412846;
}
.cta-bar.announcement-bar {
  background: none;
  box-shadow: none;
}
.text-headline {
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
  text-transform: uppercase;
}
aio-shell:not(.view-SideNav) mat-sidenav-container.sidenav-container {
  max-width: none;
}
div[layout=row] {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
@media (max-width: 480px) {
  div[layout=row] {
    display: block;
  }
}
.layout-row {
  flex-direction: row;
}
.home-rows {
  overflow: hidden;
}
@media (max-width: 600px) {
  .home-rows {
    margin: 0;
  }
}
.background-superhero-paper {
  background-size: 100%;
  background-blend-mode: multiply;
}
.home-row {
  max-width: 920px;
  margin: 32px auto;
}
.home-row .promo-img-container, .home-row .text-container {
  max-width: 50%;
}
@media (max-width: 480px) {
  .home-row .promo-img-container, .home-row .text-container {
    max-width: 100%;
    text-align: center;
  }
  .home-row .promo-img-container:nth-child(even), .home-row .text-container:nth-child(even) {
    flex-direction: column-reverse;
  }
}
.home-row .text-block {
  padding-right: 15%;
}
@media (max-width: 600px) {
  .home-row .text-block {
    padding: 0;
  }
}
.home-row .promo-img-container img {
  max-width: 90% !important;
}
.home-row .promo-img-container p {
  margin: 0 20px;
}
.home-row .promo-img-container img {
  max-width: 90%;
}
@media (max-width: 599px) {
  .home-row .promo-img-container img {
    max-width: 100%;
    float: initial !important;
  }
}
.marketing-banner {
  background-color: #5f3b66;
  margin-top: 64px;
  padding: 32px;
}
@media (max-width: 600px) {
  .marketing-banner {
    margin-top: 56px;
    padding: 18px;
  }
}
.marketing-banner .banner-headline {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 300;
  color: white;
  margin: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}
@media (max-width: 600px) {
  .marketing-banner .banner-headline {
    font-size: 18px;
    font-weight: 400;
  }
}
.marketing-banner .banner-headline:after {
  display: none;
}
.page-features .marketing-banner {
  margin-bottom: 20px;
}
aio-shell.page-home {
  line-height: initial;
}
aio-shell.page-home aio-doc-viewer {
  display: block;
  width: 100vw;
  min-height: 100vh;
  background-color: #412945;
  color: white;
}
aio-shell.page-home .mat-drawer-content {
  overflow-x: hidden;
  overflow-y: auto;
}
aio-shell.page-home header {
  background-image: url("/assets/images/header.svg");
  background-size: cover;
  background-position: center center;
  height: 80vh;
  max-height: 800px;
  color: #4b334d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 60px;
}
aio-shell.page-home header img {
  width: auto;
  height: 240px;
  max-height: 30vh;
}
aio-shell.page-home div.title {
  font-size: 60px;
  margin-top: 20px;
  text-transform: uppercase;
}
@media only screen and (max-width: 320px) {
  aio-shell.page-home div.title {
    font-size: 55px;
  }
}
aio-shell.page-home div.subtitle {
  font-size: 30px;
  margin-top: 20px;
}
@media only screen and (max-width: 320px) {
  aio-shell.page-home div.subtitle {
    font-size: 25px;
  }
}
aio-shell.page-home header h2 {
  font-size: 20px;
}
aio-shell.page-home header .cta {
  position: absolute;
  bottom: -22px;
}
aio-shell.page-home button.cta,
aio-shell.page-home a.cta {
  font-size: 18px;
  padding: 6px 28px;
  color: white;
  text-transform: uppercase;
  position: relative;
}
aio-shell.page-home h2,
aio-shell.page-home p {
  font-family: "Open Sans", sans-serif;
  max-width: 100%;
}
aio-shell.page-home h1,
aio-shell.page-home h3 {
  font-family: "PT Sans", sans-serif;
  text-transform: uppercase;
}
aio-shell.page-home .ngrx-callout {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 60px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-column-gap: 0 20px;
  justify-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
aio-shell.page-home .ngrx-callout:last-of-type {
  border-bottom: none;
}
aio-shell.page-home .ngrx-callout h3 {
  font-weight: bold;
}
@media only screen and (max-width: 600px) {
  aio-shell.page-home .ngrx-callout {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
aio-shell.page-home .ngrx-callout-description {
  flex-basis: 400px;
  flex-grow: 0;
  flex-shrink: 0;
}
aio-shell.page-home .ngrx-callout-description h3 {
  margin-bottom: 8px;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.82);
}
aio-shell.page-home .ngrx-callout-description p {
  color: rgba(255, 255, 255, 0.82);
}
@media only screen and (max-width: 600px) {
  aio-shell.page-home .ngrx-callout-description {
    flex-basis: initial;
  }
}
@media screen and (min-width: 820px) {
  aio-shell.page-home .ngrx-callout:nth-of-type(2) .ngrx-callout-figure {
    grid-column: 1/2;
    grid-row: 1/2;
    justify-self: start;
  }
  aio-shell.page-home .ngrx-callout:nth-of-type(2) .ngrx-callout-description {
    grid-column: 2/3;
    grid-row: 1/2;
  }
}
@media screen and (max-width: 820px) {
  aio-shell.page-home .ngrx-callout-description {
    padding: 0 20px 30px;
    max-width: 480px;
    text-align: center;
  }
}
aio-shell.page-home .sponsors {
  width: 100vw;
  background-color: white;
  padding: 50px 20px;
  text-align: center;
}
aio-shell.page-home .sponsors h3 {
  color: #412945;
  font-size: 24px;
  margin-bottom: 24px;
}
aio-shell.page-home .final-ngrx-callout {
  background-image: url("/assets/images/header.svg");
  background-size: cover;
  background-position: bottom center;
  height: 180px;
  color: #4b334d;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}
aio-shell.page-home .final-ngrx-callout h3 {
  font-size: 20px;
}
aio-shell.page-home .final-ngrx-callout .content {
  max-width: 960px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
aio-shell.page-home .final-ngrx-callout .cta {
  flex-shrink: 0;
}
#file-not-found {
  padding: 3rem 3rem 3rem;
}
.nf-container {
  align-items: center;
  padding: 32px;
}
.nf-response {
  margin: 32px;
  height: 100%;
  flex-direction: column;
}
.nf-response h1 {
  font-size: 48px;
  color: #412846;
  text-transform: uppercase;
  margin: 8px 0;
}
.nf-icon.material-icons {
  color: #412846;
  font-size: 100px;
  position: static;
}
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
.starting.mat-drawer-transition .mat-drawer-content {
  transition: none;
}
aio-nav-menu {
  display: block;
  margin: 0 auto;
  font-size: 14px;
  line-height: 20px;
}
aio-nav-menu ul, aio-nav-menu a {
  padding: 0;
  margin: 0;
}
aio-nav-menu:first-child {
  margin-top: 16px;
}
aio-nav-menu aio-nav-item div a {
  padding-left: 6px;
}
mat-sidenav.mat-sidenav.sidenav {
  position: fixed;
  top: 64px;
  bottom: 0;
  left: 0;
  padding: 0;
  min-width: 260px;
  background-color: #FAFAFA;
  box-shadow: 6px 0 6px rgba(0, 0, 0, 0.1);
}
mat-sidenav.mat-sidenav.sidenav.collapsed {
  top: 56px;
}
mat-sidenav-container.sidenav-container {
  min-height: 100%;
  height: auto !important;
  max-width: 100%;
  margin: 0;
  transform: none;
}
mat-sidenav-container.sidenav-container.has-floating-toc {
  max-width: 82%;
}
mat-sidenav-container div.mat-sidenav-content {
  height: auto;
}
.heading-container {
  display: flex;
  flex-direction: column;
}
.vertical-menu-item {
  box-sizing: border-box;
  color: #333;
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  max-width: 260px;
  overflow-wrap: break-word;
  padding-top: 4px;
  padding-bottom: 4px;
  text-decoration: none;
  text-align: left;
  word-wrap: break-word;
}
.vertical-menu-item:hover {
  background-color: #DBDBDB;
  color: #412846;
  text-shadow: 0 0 5px #ffffff;
}
.vertical-menu-item:focus {
  outline: #1E88E5 auto 2px;
}
.vertical-menu-item .node-title {
  flex: 1;
}
.vertical-menu-item .mat-icon {
  margin: 4px;
}
.vertical-menu-item.selected {
  color: #412846;
  font-weight: 500;
}
button.vertical-menu-item {
  border: none;
  background-color: transparent;
  margin-right: 0;
}
.heading {
  color: #333;
  cursor: pointer;
  position: relative;
}
.heading-children.expanded {
  visibility: visible;
  opacity: 1;
  max-height: 4000px;
  transition: visibility 500ms, opacity 500ms, max-height 500ms;
  transition-timing-function: ease-in-out;
}
.heading-children.collapsed {
  visibility: hidden;
  opacity: 0;
  max-height: 1px;
  transition: visibility 275ms, opacity 275ms, max-height 280ms;
  transition-timing-function: ease-out;
}
.no-animations .heading-children.expanded, .no-animations .heading-children.collapsed {
  transition: none !important;
}
.level-1 {
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-left: 14px;
  transition: background-color 0.2s;
}
.level-2 {
  color: #6e6e6e;
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-left: 12px;
  text-transform: none;
}
.level-3 {
  color: #6e6e6e;
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-left: 10px;
}
.level-4 {
  color: #6e6e6e;
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-left: 8px;
}
.level-1.expanded .mat-icon, .level-2.expanded .mat-icon {
  transform: rotate(90deg);
  transition: transform 150ms;
  transition-timing-function: ease-in-out;
}
.level-1:not(.expanded) .mat-icon, .level-2:not(.expanded) .mat-icon {
  transform: rotate(0deg);
  transition: transform 150ms;
  transition-timing-function: ease-in-out;
}
aio-nav-menu.top-menu {
  padding: 24px 0 0;
}
aio-nav-menu.top-menu aio-nav-item:last-child div {
  border-bottom: 1px solid rgba(110, 110, 110, 0.5);
}
aio-nav-menu.top-menu aio-nav-item:first-child div {
  border-top: 1px solid rgba(110, 110, 110, 0.5);
}
mat-sidenav .doc-version {
  padding: 8px;
  border-top: 1px solid #DBDBDB;
}
mat-sidenav .doc-version select {
  outline: none;
  width: 100%;
  background: rgba(219, 219, 219, 0.5);
  height: 32px;
  border: 1px solid #DBDBDB;
  color: #333;
}
mat-sidenav .doc-version select option {
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 16px;
  line-height: 24px;
}
nav#main-table-of-contents {
  width: 200px;
  height: 900px;
  position: fixed;
  right: 0;
  top: 50px;
  bottom: 100px;
  margin-left: 32px;
  background-color: #412846;
}
mat-toolbar.mat-toolbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
}
mat-toolbar.mat-toolbar mat-toolbar-row {
  padding: 0 16px 0 0;
}
mat-toolbar.mat-toolbar mat-icon {
  color: #FFFFFF;
}
aio-shell.page-home mat-toolbar.mat-toolbar {
  background-color: #412846;
}
@media (min-width: 481px) {
  aio-shell.page-home mat-toolbar.mat-toolbar:not(.transitioning) {
    background-color: transparent;
    transition: background-color 0.2s linear;
  }
}
aio-shell.page-home mat-toolbar.mat-toolbar,
aio-shell.page-features mat-toolbar.mat-toolbar,
aio-shell.page-events mat-toolbar.mat-toolbar,
aio-shell.page-resources mat-toolbar.mat-toolbar {
  box-shadow: none;
}
@media (min-width: 481px) {
  aio-shell.page-home mat-toolbar.mat-toolbar,
aio-shell.page-features mat-toolbar.mat-toolbar,
aio-shell.page-events mat-toolbar.mat-toolbar,
aio-shell.page-resources mat-toolbar.mat-toolbar {
    position: absolute;
  }
}
@media (min-width: 992px) {
  aio-shell.folder-api mat-toolbar.mat-toolbar .hamburger.mat-button,
aio-shell.folder-docs mat-toolbar.mat-toolbar .hamburger.mat-button,
aio-shell.folder-guide mat-toolbar.mat-toolbar .hamburger.mat-button,
aio-shell.folder-tutorial mat-toolbar.mat-toolbar .hamburger.mat-button {
    margin: 0 8px 0 0;
  }
}
.hamburger.mat-button {
  height: 100%;
  margin: 0 8px 0 0;
  padding: 0;
}
@media (min-width: 992px) {
  .hamburger.mat-button {
    margin: 0 16px 0 -64px;
  }
}
@media (max-width: 480px) {
  .hamburger.mat-button {
    min-width: 15%;
  }
}
.hamburger.mat-button:not(.starting) {
  transition-duration: 0.4s;
  transition-property: color, margin;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
}
.hamburger.mat-button:hover {
  color: #FAFAFA;
}
.hamburger.mat-button .mat-icon {
  color: white;
  position: inherit;
}
.nav-link.home {
  cursor: pointer;
  margin: 0 16px 0 0;
  padding: 21px 0;
}
@media screen and (max-width: 480px) {
  .nav-link.home {
    margin-right: 8px;
  }
}
.nav-link.home img {
  position: relative;
  margin-top: -21px;
  top: 12px;
  height: 40px;
}
@media (max-width: 992px) {
  .nav-link.home img:hover {
    transform: scale(1.1);
  }
}
aio-top-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
aio-top-menu ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-position: inside;
  padding: 0px;
  margin: 0px;
}
aio-top-menu ul li {
  padding-bottom: 2px;
  list-style-type: none;
  cursor: pointer;
}
aio-top-menu ul li:hover {
  opacity: 0.7;
}
aio-top-menu ul li:focus {
  background-color: #1E88E5;
  outline: none;
}
aio-top-menu a.nav-link {
  margin: 0;
  padding: 24px 16px;
  cursor: pointer;
}
aio-top-menu a.nav-link:focus {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 4px;
  outline: none;
  padding: 8px 16px;
}
aio-search-box.search-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  min-width: 150px;
  height: 100%;
}
aio-search-box.search-container input {
  color: #333;
  border: none;
  border-radius: 100px;
  background-color: #FAFAFA;
  padding: 5px 16px;
  margin-left: 8px;
  width: 180px;
  max-width: 240px;
  height: 50%;
  -webkit-appearance: none;
}
aio-search-box.search-container input:focus {
  outline: none;
}
@media (min-width: 1000px) {
  aio-search-box.search-container input {
    transition: width 0.4s ease-in-out;
  }
  aio-search-box.search-container input:focus {
    width: 500px;
  }
}
@media (max-width: 480px) {
  aio-search-box.search-container input {
    width: 150px;
  }
}
.app-toolbar .toolbar-external-icons-container {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.app-toolbar .toolbar-external-icons-container a {
  display: flex;
  align-items: center;
  margin-left: 16px;
}
@media screen and (max-width: 480px) {
  .app-toolbar .toolbar-external-icons-container a {
    margin-left: 8px;
  }
}
.app-toolbar .toolbar-external-icons-container a:hover {
  opacity: 0.8;
}
.app-toolbar .toolbar-external-icons-container a img {
  height: 24px;
}
/* ==============================
   MODULE STYLES
   ============================== */
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
.alert, .callout {
  padding: 16px;
  margin: 24px 0px;
  font-size: 16px;
  line-height: 24px;
  color: #333;
  width: 100%;
  box-sizing: border-box;
  clear: both;
}
.alert h1, .callout h1, .alert h2, .callout h2, .alert h3, .callout h3, .alert h4, .callout h4, .alert h5, .callout h5, .alert h6, .callout h6 {
  font-weight: 500;
}
.alert.is-critical, .is-critical.callout {
  border-left: 8px solid #DD0031;
  background-color: rgba(221, 0, 49, 0.05);
}
.alert.is-critical h1, .is-critical.callout h1, .alert.is-critical h2, .is-critical.callout h2, .alert.is-critical h3, .is-critical.callout h3, .alert.is-critical h4, .is-critical.callout h4, .alert.is-critical h5, .is-critical.callout h5, .alert.is-critical h6, .is-critical.callout h6 {
  color: #DD0031;
}
.alert.is-important, .is-important.callout {
  border-left: 8px solid #FF9800;
  background-color: rgba(255, 152, 0, 0.05);
}
.alert.is-important h1, .is-important.callout h1, .alert.is-important h2, .is-important.callout h2, .alert.is-important h3, .is-important.callout h3, .alert.is-important h4, .is-important.callout h4, .alert.is-important h5, .is-important.callout h5, .alert.is-important h6, .is-important.callout h6 {
  color: #FF9800;
}
.alert.is-helpful, .is-helpful.callout {
  border-left: 8px solid #412846;
  background-color: rgba(65, 40, 70, 0.05);
}
.alert.is-helpful h1, .is-helpful.callout h1, .alert.is-helpful h2, .is-helpful.callout h2, .alert.is-helpful h3, .is-helpful.callout h3, .alert.is-helpful h4, .is-helpful.callout h4, .alert.is-helpful h5, .is-helpful.callout h5, .alert.is-helpful h6, .is-helpful.callout h6 {
  color: #412846;
}
.alert > *, .callout > * {
  margin: 8px 16px;
}
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
.github-links {
  float: right;
}
.github-links .material-icons {
  border-radius: 4px;
  padding: 4px;
  font-size: 20px;
  line-height: 28px;
}
.github-links .material-icons:hover {
  background-color: #ECEFF1;
}
.api-header {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .api-header {
    flex-direction: column;
    align-items: flex-start;
  }
}
.api-body .class-overview {
  position: relative;
}
.api-body .class-overview code-example {
  clear: left;
}
.api-body .method-table .with-github-links, .api-body .option-table .with-github-links, .api-body .list-table .with-github-links {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.api-body .method-table .with-github-links .github-links a, .api-body .option-table .with-github-links .github-links a, .api-body .list-table .with-github-links .github-links a {
  color: #6e6e6e;
}
.api-body .method-table .with-github-links .github-links a .material-icons:hover, .api-body .option-table .with-github-links .github-links a .material-icons:hover, .api-body .list-table .with-github-links .github-links a .material-icons:hover {
  background: none;
  color: #412846;
}
.api-body .method-table h3, .api-body .option-table h3, .api-body .list-table h3 {
  margin: 6px 0;
  font-weight: bold;
  clear: left;
}
.api-body .method-table h4, .api-body .option-table h4, .api-body .list-table h4 {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  margin-top: 12px;
}
.api-body .api-heading {
  padding: 5px 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}
.api-body .short-description {
  margin: 6px 0 0 10px;
}
.api-body .properties-table {
  font-size: 14px;
  line-height: 20px;
}
.api-body .properties-table thead th:nth-child(1) {
  width: 20%;
}
.api-body .properties-table thead th:nth-child(2) {
  width: 20%;
}
.api-body .parameters-table {
  margin-top: 0;
  font-size: 14px;
  line-height: 20px;
}
.api-body .parameters-table td:nth-child(1) {
  width: 20%;
}
.api-body details.overloads {
  margin-left: -8px;
}
.api-body details.overloads summary {
  height: inherit;
  padding: 8px 12px;
}
.api-body details.overloads summary h4 {
  margin: 0;
  clear: left;
}
.api-body .from-constructor, .api-body .read-only-property {
  font-style: italic;
  color: #412846;
}
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
/* API EDIT ICON */
#api .api-filter .material-icons {
  right: 48px;
}
/* API LIST STYLES */
aio-api-list div.form-search i.material-icons {
  width: 20px;
  pointer-events: none;
}
aio-api-list .form-search input {
  width: 182px;
}
aio-api-list .api-list-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
aio-api-list .api-list-container h2 {
  margin-top: 16px;
}
.api-filter {
  display: flex;
  margin: 0 auto;
}
@media (max-width: 600px) {
  .api-filter {
    flex-direction: column;
    margin: 16px auto;
  }
}
.api-filter .form-select-menu, .api-filter .form-search {
  margin: 8px;
}
/* LAYOUT */
.docs-content {
  position: relative;
}
.l-content-small {
  padding: 16px;
  max-width: 1100px;
  margin: 0;
}
@media handheld and (max-width: 480px), screen and (max-device-width: 480px), screen and (max-width: 800px) {
  .l-content-small {
    padding: 24px 0 0;
  }
}
/* SEARCH BAR */
.form-search {
  position: relative;
}
.form-search input {
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
  box-sizing: border-box;
  border: 1px solid #FFFFFF;
  color: #8E24AA;
  font-size: 16px;
  line-height: 24px;
  height: 32px;
  line-height: 32px;
  outline: none;
  padding: 0 16px 0 32px;
  transition: all 0.2s;
}
.form-search input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #CFD8DC;
  font-size: 14px;
  line-height: 20px;
}
.form-search input::-moz-placeholder {
  /* Firefox 19+ */
  color: #CFD8DC;
  font-size: 14px;
  line-height: 20px;
}
.form-search input:-ms-input-placeholder {
  /* IE 10+ */
  color: #CFD8DC;
  font-size: 14px;
  line-height: 20px;
}
.form-search input:-moz-placeholder {
  /* Firefox 18- */
  color: #CFD8DC;
  font-size: 14px;
  line-height: 20px;
}
.form-search input:focus {
  border: 1px solid #42A5F5;
  box-shadow: 0 2px 2px rgba(66, 165, 245, 0.24), 0 0 2px rgba(66, 165, 245, 0.12);
}
.form-search .material-icons {
  color: #CFD8DC;
  font-size: 20px;
  line-height: 28px;
  line-height: 32px;
  height: 100%;
  left: 8px;
  position: absolute;
  z-index: 1;
}
/* API SYMBOLS */
/* SYMBOL CLASS */
.symbol {
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(10, 16, 20, 0.24);
  color: #FFFFFF;
  display: inline-block;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  width: 16px;
}
.symbol.all {
  background: #FFFFFF;
}
.symbol.all:before {
  content: " ";
}
.symbol.decorator {
  background: #1565C0;
}
.symbol.decorator:before {
  content: "@";
}
.symbol.directive {
  background: #D81B60;
}
.symbol.directive:before {
  content: "D";
}
.symbol.pipe {
  background: #546E7A;
}
.symbol.pipe:before {
  content: "P";
}
.symbol.class {
  background: #2196F3;
}
.symbol.class:before {
  content: "C";
}
.symbol.interface {
  background: #009688;
}
.symbol.interface:before {
  content: "I";
}
.symbol.function {
  background: #4CAF50;
}
.symbol.function:before {
  content: "F";
}
.symbol.enum {
  background: #FFA000;
}
.symbol.enum:before {
  content: "E";
}
.symbol.const {
  background: #6e6e6e;
}
.symbol.const:before {
  content: "K";
}
.symbol.let {
  background: #6e6e6e;
}
.symbol.let:before {
  content: "K";
}
.symbol.var {
  background: #6e6e6e;
}
.symbol.var:before {
  content: "K";
}
.symbol.ngmodule {
  background: #940;
}
.symbol.ngmodule:before {
  content: "M";
}
.symbol.type-alias {
  background: #7CB342;
}
.symbol.type-alias:before {
  content: "T";
}
.symbol.package {
  background: #8E24AA;
}
.symbol.package:before {
  content: "Pk";
}
/* API HOMEE PAGE */
/* API FILTER MENU */
.api-filter aio-select {
  width: 200px;
}
.api-filter aio-select .symbol {
  margin-right: 8px;
}
/* API CLASS LIST */
.docs-content .api-list {
  list-style: none;
  margin: 0 0 32px -8px;
  padding: 0;
  overflow: hidden;
}
@media screen and (max-width: 600px) {
  .docs-content .api-list {
    margin: 0 0 0 -8px;
  }
}
.docs-content .api-list li {
  font-size: 14px;
  margin: 8px 0;
  line-height: 14px;
  padding: 0;
  float: left;
  width: 33%;
  overflow: hidden;
  min-width: 220px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.docs-content .api-list li .symbol {
  margin-right: 8px;
}
.docs-content .api-list li a {
  color: #546E7A;
  display: inline-block;
  line-height: 16px;
  padding: 0 16px 0;
  text-decoration: none;
  transition: all 0.3s;
  overflow: hidden;
  text-overflow: ellipsis;
}
.docs-content .api-list li a:hover {
  background: #ECEFF1;
  color: #2196F3;
}
.docs-content .h2-api-docs,
.docs-content .h2-api-docs:first-of-type {
  font-size: 18px;
  line-height: 24px;
  margin-top: 0;
}
.code-links a code, .code-links a .api-doc-code {
  color: #1E88E5 !important;
}
.openParens {
  margin-top: 15px;
}
.endParens {
  margin-bottom: 20px !important;
}
p.selector {
  margin: 0;
}
p.location-badge {
  margin: 0 0 16px 16px !important;
}
p .api-doc-code {
  border-bottom: 0;
}
p .api-doc-code :hover {
  border-bottom: none;
}
.row-margin {
  margin-bottom: 36px;
}
.row-margin h2 {
  line-height: 28px;
}
.code-margin {
  margin-bottom: 8px;
}
.no-bg {
  background: none;
  padding: 0;
}
.no-bg-with-indent {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 16px;
  margin-top: 6px;
  margin-bottom: 0;
  background: none;
}
.code-background {
  padding: 0 5px 0;
}
.code-background span.pln {
  color: #1E88E5 !important;
}
.code-anchor {
  cursor: pointer;
  text-decoration: none;
  font-size: inherit;
}
.code-anchor:hover {
  text-decoration: underline;
}
.api-doc-code {
  font-size: 16px;
  line-height: 24px;
  color: #1a2326;
}
.api-doc-code.no-pln .pln:last-child {
  display: none;
}
@media screen and (max-width: 600px) {
  .docs-content .layout-xs-column {
    display: block !important;
  }

  .api-doc-code {
    font-size: 12px;
  }

  p.location-badge {
    position: relative;
    font-size: 11px;
  }
}
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
/* Button Styles */
.button,
a.button.mat-button {
  display: inline-block;
  line-height: 32px;
  padding: 0px 16px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  border-radius: 3px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  border: none;
}
.button.button-small,
a.button.mat-button.button-small {
  font-size: 14px;
  line-height: 20px;
  line-height: 24px;
  padding: 0px 8px;
}
.button.button-large,
a.button.mat-button.button-large {
  font-size: 16px;
  line-height: 24px;
  line-height: 48px;
  padding: 0px 24px;
}
.button.button-x-large,
a.button.mat-button.button-x-large {
  font-size: 20px;
  line-height: 28px;
  line-height: 56px;
  padding: 0px 24px;
}
.button.button-secondary,
a.button.mat-button.button-secondary {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.87);
}
.button.button-plain,
a.button.mat-button.button-plain {
  background: #FFFFFF;
  color: rgba(51, 51, 51, 0.87);
}
.button.button-subtle,
a.button.mat-button.button-subtle {
  background: #6e6e6e;
  color: #e1e1e1;
}
.button.button-subtle:hover,
a.button.mat-button.button-subtle:hover {
  color: rgba(255, 255, 255, 0.7);
}
.button.button-blue,
a.button.mat-button.button-blue {
  background: #412846;
  color: rgba(255, 255, 255, 0.87);
}
.button.button-blue:hover,
a.button.mat-button.button-blue:hover {
  color: rgba(255, 255, 255, 0.7);
}
.button.button-banner,
a.button.mat-button.button-banner {
  background: #333;
  color: rgba(255, 255, 255, 0.87);
}
.button.button-shield, .button.button-shield.mat-button,
a.button.mat-button.button-shield,
a.button.mat-button.button-shield.mat-button {
  background-color: #412846;
  background: #412846 url("/assets/images/logos/angular/angular_whiteTransparent.svg") 24px 13px no-repeat;
  color: rgba(255, 255, 255, 0.87);
  padding-left: 54px;
  background-size: 22px 22px;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
  .button.button-shield, .button.button-shield.mat-button,
a.button.mat-button.button-shield,
a.button.mat-button.button-shield.mat-button {
    background: #412846 url("/assets/images/logos/angular/angular_whiteTransparent.svg") 24px 13px no-repeat;
    background-size: 22px 22px;
  }
}
.cta-bar {
  text-align: center;
}
.cta-bar .button {
  margin: 0px 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  transition: all 0.2s ease-in-out;
}
.cta-bar .button:hover {
  transform: scale(1.1);
  color: #FAFAFA;
}
a.filter-button {
  width: 140px;
  font-size: 16px;
  line-height: 24px;
  line-height: 48px;
  padding: 0px 16px;
  margin: 8px;
  border: 2px solid #412846;
  border-radius: 4px;
}
a.filter-button:hover {
  background-color: #412846;
  color: white;
}
[mat-button], [mat-raised-button], [mat-button], [mat-raised-button] {
  text-transform: uppercase;
}
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
.callout {
  padding: 0px;
  border-left: none !important;
  border-radius: 4px;
}
.callout header {
  color: #FFFFFF;
  line-height: 24px;
  font-weight: 500;
  padding: 8px 16px;
  margin: 0;
  text-transform: uppercase;
  border-radius: 4px 4px 0 0;
}
.callout p {
  padding: 16px;
  margin: 0px;
  font-size: 16px;
  line-height: 24px;
}
.callout.is-critical {
  border-color: #DD0031;
  background: rgba(221, 0, 49, 0.05);
}
.callout.is-critical header {
  background: #DD0031;
}
.callout.is-important {
  border-color: #FF9800;
  background: rgba(255, 152, 0, 0.05);
}
.callout.is-important header {
  background: #FFA000;
}
.callout.is-helpful {
  border-color: #412846;
  background: rgba(65, 40, 70, 0.05);
}
.callout.is-helpful header {
  background: #412846;
}
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
.card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 24px 0;
}
.docs-card {
  height: 194px;
  width: 30%;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
  box-sizing: border-box;
  transition: box-shadow 0.5s;
  max-width: 340px;
  min-width: 262px;
  margin: 24px 8px;
  padding-bottom: 48px;
  position: relative;
}
.docs-card:hover {
  box-shadow: 0 8px 8px rgba(10, 16, 20, 0.24), 0 0 8px rgba(10, 16, 20, 0.12);
  text-decoration: none;
}
.docs-card:hover {
  text-decoration: none;
}
.docs-card:hover section {
  color: #412846;
}
.docs-card:hover p {
  color: #333;
  padding: 0 16px;
}
.docs-card:hover .card-footer {
  line-height: 32px;
  padding: 8px 16px;
  background-color: rgba(65, 40, 70, 0.1);
  color: #412846;
}
.docs-card section {
  color: #333;
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  padding: 32px 0 24px;
  text-transform: none;
  text-align: center;
}
.docs-card p {
  color: #333;
  font-size: 14px;
  line-height: 20px;
  padding: 0 16px;
  margin: 0;
  text-align: center;
}
.docs-card .card-footer {
  bottom: 0;
  border-top: 0.5px solid #DBDBDB;
  box-sizing: border-box;
  line-height: 48px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: right;
  color: #6e6e6e;
}
.docs-card .card-footer a {
  color: #6e6e6e;
  font-size: 14px;
  line-height: 20px;
}
.docs-card .card-footer.center {
  text-align: center;
}
.card-section {
  height: auto;
  width: 90%;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
  box-sizing: border-box;
  transition: box-shadow 0.5s;
  padding: 16px 32px;
  margin: 16px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.card-section:hover {
  box-shadow: 0 8px 8px rgba(10, 16, 20, 0.24), 0 0 8px rgba(10, 16, 20, 0.12);
  text-decoration: none;
}
.card-section:hover {
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
}
.card-section h1, .card-section h2, .card-section h3, .card-section h4, .card-section h5, .card-section h6 {
  margin: 8px 0;
}
.card-section a, .card-section .button, .card-section button {
  text-align: center;
}
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
code-example, code-tabs {
  clear: both;
  display: block;
}
code-example:not(.no-box) {
  background-color: rgba(241, 241, 241, 0.2);
  border: 0.5px solid #DBDBDB;
  border-radius: 5px;
  color: #333;
  margin: 16px auto;
}
code-example.no-box pre {
  margin: 0;
}
code-example.no-box code {
  background-color: transparent;
}
code-example code {
  overflow: auto;
}
code-example .mat-card, code-tabs .mat-card {
  padding: 0;
  border-radius: 5px;
}
code-example code, code-tabs code {
  overflow: auto;
}
code-example.code-shell, code-example[language=sh], code-example[language=bash] {
  background-color: #333;
}
code-example header {
  background-color: #412846;
  border-radius: 5px 5px 0 0;
  color: #FAFAFA;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 16px;
}
code-example.avoid header,
code-example.avoidFile header {
  border: 2px solid #DD0031;
  background: #DD0031;
}
code-example.avoid,
code-example.avoidFile,
code-tabs.avoid mat-tab-body,
code-tabs.avoidFile mat-tab-body {
  border: 0.5px solid #DD0031;
}
code-tabs div .mat-tab-body-content {
  height: auto;
}
code-tabs .mat-tab-body-wrapper mat-tab-body .mat-tab-body {
  overflow-y: hidden;
}
code-tabs mat-tab-body-content .fadeIn {
  -webkit-animation: opacity 2s ease-in;
          animation: opacity 2s ease-in;
}
aio-code pre {
  display: flex;
  min-height: 32px;
  margin: 16px 24px;
  white-space: pre-wrap;
  align-items: center;
}
aio-code pre code span {
  line-height: 24px;
}
.code-missing {
  color: #C3002F;
}
.copy-button {
  position: absolute;
  top: -7px;
  right: -19px;
  padding: 0;
  color: #B0BEC5;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.copy-button:hover {
  color: #6e6e6e;
}
.lang-sh .copy-button, .lang-bash .copy-button {
  color: #6e6e6e;
}
.lang-sh .copy-button:hover, .lang-bash .copy-button:hover {
  color: #DBDBDB;
}
.code-tab-group .mat-tab-label {
  white-space: nowrap;
}
.code-tab-group .mat-tab-label:hover {
  background: rgba(0, 0, 0, 0.04);
}
.code-tab-group .mat-tab-body-content {
  height: auto;
  transform: none;
}
[role=tabpanel] {
  transition: none;
}
.sidenav-content code a {
  color: inherit;
  font-size: inherit;
}
/* PRETTY PRINTING STYLES for prettify.js. */
.prettyprint {
  position: relative;
}
/* Specify class=linenums on a pre to get line numbering */
ol.linenums {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  color: #B3B6B7;
}
ol.linenums li {
  margin: 0;
  font-family: "Droid Sans Mono", monospace;
  font-size: 90%;
  line-height: 24px;
}
/* The following class|color styles are derived from https://github.com/google/code-prettify/blob/master/src/prettify.css*/
/* SPAN elements with the classes below are added by prettyprint. */
.pln {
  color: #000;
}
/* plain text */
@media screen {
  .str {
    color: #800;
  }

  /* string content */
  .kwd {
    color: #fc8219;
  }

  /* a keyword */
  .com {
    color: #060;
  }

  /* a comment */
  .typ {
    color: purple;
  }

  /* a type name */
  .lit {
    color: #fc8219;
  }

  /* a literal value */
  /* punctuation, lisp open bracket, lisp close bracket */
  .pun, .opn, .clo {
    color: #660;
  }

  .tag {
    color: #008;
  }

  /* a markup tag name */
  .atn {
    color: #606;
  }

  /* a markup attribute name */
  .atv {
    color: #800;
  }

  /* a markup attribute value */
  .dec, .var {
    color: #606;
  }

  /* a declaration; a variable name */
  .fun {
    color: red;
  }

  /* a function name */
}
/* Use higher contrast and text-weight for printable form. */
@media print, projection {
  .str {
    color: #060;
  }

  .kwd {
    color: #006;
    font-weight: bold;
  }

  .com {
    color: #600;
    font-style: italic;
  }

  .typ {
    color: #404;
    font-weight: bold;
  }

  .lit {
    color: #044;
  }

  .pun, .opn, .clo {
    color: #440;
  }

  .tag {
    color: #006;
    font-weight: bold;
  }

  .atn {
    color: #404;
  }

  .atv {
    color: #060;
  }
}
/* SHELL / TERMINAL CODE BLOCKS */
code-example.code-shell .pnk, code-example.code-shell .blk, code-example.code-shell .pln, code-example.code-shell .otl, code-example.code-shell .kwd, code-example.code-shell .typ, code-example.code-shell .tag, code-example.code-shell .str, code-example.code-shell .atv, code-example.code-shell .atn, code-example.code-shell .com, code-example.code-shell .lit, code-example.code-shell .pun, code-example.code-shell .dec, code-example[language=sh] .pnk, code-example[language=sh] .blk, code-example[language=sh] .pln, code-example[language=sh] .otl, code-example[language=sh] .kwd, code-example[language=sh] .typ, code-example[language=sh] .tag, code-example[language=sh] .str, code-example[language=sh] .atv, code-example[language=sh] .atn, code-example[language=sh] .com, code-example[language=sh] .lit, code-example[language=sh] .pun, code-example[language=sh] .dec, code-example[language=bash] .pnk, code-example[language=bash] .blk, code-example[language=bash] .pln, code-example[language=bash] .otl, code-example[language=bash] .kwd, code-example[language=bash] .typ, code-example[language=bash] .tag, code-example[language=bash] .str, code-example[language=bash] .atv, code-example[language=bash] .atn, code-example[language=bash] .com, code-example[language=bash] .lit, code-example[language=bash] .pun, code-example[language=bash] .dec {
  color: #17ff0b;
}
.contribute-container h2 {
  margin: 0;
}
.contribute-container .card-section {
  justify-content: space-between;
  max-width: 880px;
}
.contribute-container .card-section > :first-child {
  margin-right: 2rem;
  width: 60%;
}
.contribute-container .card-section:last-child {
  margin-bottom: 0;
}
@media handheld and (max-width: 480px), screen and (max-width: 480px), screen and (max-width: 900px) {
  aio-contributor-list .grid-fluid {
    width: auto;
  }
}
@media handheld and (max-width: 480px), screen and (max-width: 480px), screen and (max-width: 900px) {
  aio-contributor-list .grid-fluid {
    margin-left: 20px;
    margin-right: 20px;
    float: none;
    display: block;
    width: auto;
  }
}
.group-buttons {
  margin: 32px auto;
}
.group-buttons a.selected {
  background-color: #412846;
  color: white;
}
.contributor-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
aio-contributor {
  background: #FFFFFF;
  margin: 8px;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
  transition: all 0.3s;
  perspective: 800px;
}
aio-contributor:hover {
  transform: translate3d(0, -3px, 0);
  box-shadow: 0 8px 8px rgba(10, 16, 20, 0.24), 0 0 8px rgba(10, 16, 20, 0.12);
}
aio-contributor:hover .contributor-image {
  transform: scale(1.05);
}
aio-contributor:hover .contributor-info {
  opacity: 1;
}
aio-contributor .contributor-info {
  background: rgba(51, 51, 51, 0.5);
  height: 168px;
  width: 168px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0;
  border-radius: 50%;
}
aio-contributor .contributor-info [mat-button] {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  margin: 8px;
  padding: 0;
}
aio-contributor .contributor-info [mat-button]:hover {
  color: #DBDBDB;
}
aio-contributor .contributor-info [mat-button].icon {
  min-width: 20px;
  width: 20px;
}
aio-contributor .contributor-info [mat-button].icon .fa-2x {
  font-size: 20px;
}
aio-contributor div.contributor-card {
  width: 250px;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  transform-style: preserve-3d;
  transition: transform ease 500ms;
}
aio-contributor div.contributor-card h3 {
  margin: 8px 0;
}
aio-contributor div.contributor-card .card-front, aio-contributor div.contributor-card .card-back {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
aio-contributor div.contributor-card .card-front {
  justify-content: center;
}
aio-contributor div.contributor-card .card-back {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 24px;
  transform: rotateY(180deg);
}
aio-contributor div.contributor-card .card-back section {
  display: none;
}
aio-contributor div.contributor-card .card-back p {
  margin: 8px 0;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  overflow: hidden;
}
aio-contributor div.contributor-card.flipped {
  transform: rotateY(180deg);
}
aio-contributor div.contributor-card.flipped .card-front {
  display: none;
}
aio-contributor .contributor-image {
  display: flex;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  height: 168px;
  width: 168px;
  background-size: cover;
  background-position: center;
  margin: 8px auto;
  border: 2px solid #DBDBDB;
  transition: all 0.2s ease-in-out;
}
aio-contributor section {
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}
aio-contributor p {
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  margin: 8px 16px;
  text-overflow: ellipsis;
  overflow: scroll;
  font-weight: 400;
}
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
/*
 * General styling to make detail/summary tags look a bit more material
 * To get the best out of it you should structure your usage like this:
 *
 * ```
 * <details>
 *   <summary>Some title</summary>
 *   <div class="details-content">
 *     Some content
 *   </div>
 *  </details>
 *
 */
summary {
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  position: relative;
  padding: 16px 24px;
  color: #0A1014;
  height: 16px;
  display: block;
}
summary::-webkit-details-marker {
  display: none;
}
summary::before {
  content: "";
  font-family: "Material Icons";
  font-size: 24px;
  line-height: 30px;
  -webkit-font-smoothing: antialiased;
  transform: rotate(0deg);
  transition: transform 150ms;
  transition-timing-function: ease-in-out;
  float: right;
}
details {
  box-shadow: 0 1px 4px 0 rgba(10, 16, 20, 0.37);
}
details .detail-contents {
  padding: 16px 24px;
}
details[open] > summary::before {
  transform: rotate(180deg);
  transition: transform 150ms;
  transition-timing-function: ease-in-out;
}
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
.edit-page-cta {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #412846;
  text-align: right;
  margin-right: 32px;
  display: block;
  position: absolute;
  right: 0;
}
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
.feature-section {
  margin: 0 0 32px;
}
.feature-section .feature-header, .feature-section .text-headline {
  text-align: center;
}
.feature-section .feature-header img {
  margin: 16px;
}
.feature-section .feature-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin: 8px 0px;
  clear: both;
}
.feature-section .feature-row {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 600px) {
  .feature-section .feature-row {
    flex-direction: column;
  }
}
.feature-section .feature-row .feature {
  max-width: 300px;
  margin: 0 16px;
}
@media (max-width: 768px) {
  .feature-section .feature-row .feature {
    max-width: 100%;
  }
}
.filetree {
  background: #FAFAFA;
  border: 4px solid #DBDBDB;
  border-radius: 4px;
  margin: 0 0 24px 0;
  padding: 16px 32px;
}
.filetree .file {
  display: block;
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: 0.3px;
  line-height: 32px;
  color: #333;
}
.filetree .children {
  padding-left: 24px;
  position: relative;
  overflow: hidden;
}
.filetree .children .file {
  position: relative;
}
.filetree .children .file:before {
  content: "";
  left: -18px;
  bottom: 16px;
  width: 16px;
  height: 9999px;
  position: absolute;
  border-width: 0 0 1px 1px;
  border-style: solid;
  border-color: #DBDBDB;
  border-radius: 0 0 0 3px;
}
.sidenav-content h1 .header-link, .sidenav-content h2 .header-link, .sidenav-content h3 .header-link, .sidenav-content h4 .header-link, .sidenav-content h5 .header-link, .sidenav-content h6 .header-link {
  color: #6e6e6e;
  margin: 0 4px;
  text-decoration: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  visibility: hidden;
  display: inline-block;
  vertical-align: text-top;
  width: 1px;
}
.sidenav-content h1:hover .header-link, .sidenav-content h2:hover .header-link, .sidenav-content h3:hover .header-link, .sidenav-content h4:hover .header-link, .sidenav-content h5:hover .header-link, .sidenav-content h6:hover .header-link {
  visibility: visible;
}
hr {
  border: none;
  background: #36203A;
  height: 1px;
}
.hr-margin {
  display: block;
  height: 1px;
  border: 0;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 0;
}
.content img.right {
  clear: both;
  float: right;
  margin-left: 20px;
  margin-bottom: 20px;
}
.content img.left {
  clear: both;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}
@media (max-width: 1300px) {
  .content img {
    max-width: 100%;
    height: auto;
  }
}
@media (max-width: 600px) {
  .content img {
    float: none !important;
  }
  .content img.right {
    margin-left: 0;
  }
  .content img.left {
    margin-right: 0;
  }
}
.content figure {
  border-radius: 4px;
  background: #FFFFFF;
  padding: 20px;
  display: inline-block;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);
  margin: 0 0 14px 0;
}
.content figure img {
  border-radius: 4px;
}
.progress-bar-container {
  height: 2px;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 11;
}
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
table {
  margin: 24px 0px;
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
  border-radius: 2px;
  background: #FAFAFA;
}
table.is-full-width {
  width: 100%;
}
table.is-fixed-layout {
  table-layout: fixed;
}
table thead > {
  vertical-align: middle;
  border-color: inherit;
}
table thead > tr {
  vertical-align: inherit;
  border-color: inherit;
}
table thead > tr > th {
  background: rgba(219, 219, 219, 0.2);
  border-bottom: 1px solid #DBDBDB;
  color: #333;
  font-size: 14px;
  line-height: 20px;
  line-height: 28px;
  font-weight: 500;
  padding: 8px 24px;
  text-align: left;
  text-transform: uppercase;
}
table tbody > tr > th,
table tbody > tr > td {
  border-bottom: 1px solid #DBDBDB;
  padding: 16px;
  text-align: left;
  line-height: 24px;
  vertical-align: top;
}
@media (max-width: 480px) {
  table tbody > tr > th:before,
table tbody > tr > td:before {
    display: inline-block;
  }
}
table tbody > tr > td {
  letter-spacing: 0.3px;
}
@media (max-width: 480px) {
  table tbody > tr > td tr td:first-child {
    background-color: #DBDBDB;
  }
}
table tbody > tr > th {
  background: rgba(219, 219, 219, 0.2);
  border-right: 1px solid #DBDBDB;
  font-weight: 600;
  max-width: 100px;
}
table tbody > tr:last-child td {
  border: none;
}
@media (max-width: 480px) {
  table tbody > tr:last-child td {
    border-bottom: 1px solid #DBDBDB;
  }
}
#cheatsheet table tbody td {
  overflow: auto;
}
@media only screen and (max-width: 990px) {
  #cheatsheet {
    /* Force table to not be like tables anymore */
  }
  #cheatsheet table, #cheatsheet thead, #cheatsheet tbody, #cheatsheet tfoot, #cheatsheet tr, #cheatsheet th, #cheatsheet td {
    display: block;
    position: relative;
    max-width: 100%;
  }
  #cheatsheet table code, #cheatsheet thead code, #cheatsheet tbody code, #cheatsheet tfoot code, #cheatsheet tr code, #cheatsheet th code, #cheatsheet td code {
    padding: 0;
    background-color: inherit;
  }
  #cheatsheet th {
    border-right: none;
  }
  #cheatsheet th:not(:last-child), #cheatsheet td:not(:last-child) {
    border-bottom: none;
    padding-bottom: 0px;
  }
}
.presskit-container {
  padding: 0 32px 32px 32px;
}
.presskit-container h2 {
  color: #37474F;
}
.presskit-container .l-space-left-3 {
  margin-left: 24px;
}
.presskit-container .cc-by-anchor {
  text-decoration: underline;
  color: grey !important;
}
.presskit-container .presskit-row {
  margin: 48px 0;
  width: 100%;
}
.presskit-container .presskit-row .presskit-inner {
  display: flex;
  align-items: center;
}
@media (max-width: 599px) {
  .presskit-container .presskit-row .presskit-inner {
    flex-direction: column;
  }
}
.presskit-container .presskit-row .presskit-inner h3 {
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
  color: #455A64;
}
@media (max-width: 599px) {
  .presskit-container .presskit-row .presskit-inner h3 {
    padding-bottom: 16px;
  }
}
.presskit-container .presskit-row .presskit-inner .transparent-img-bg {
  margin-top: 10px;
  border-radius: 4px;
  width: 128px;
  height: 128px;
  background-color: #34474F;
}
.presskit-container .presskit-row .presskit-inner ul {
  padding: 0;
  list-style-type: none;
}
@media (max-width: 599px) {
  .presskit-container .presskit-row .presskit-inner ul {
    padding: 0 !important;
    margin: 0 !important;
  }
}
.presskit-container .presskit-row .presskit-inner ul li {
  margin: 0 0 8px 0;
}
@media (max-width: 599px) {
  .presskit-container .presskit-row .presskit-image-container {
    text-align: center;
  }
}
.presskit-container .presskit-row .presskit-image-container img {
  height: 128px;
  width: auto;
  margin-bottom: 16px;
}
.presskit-container .presskit-row:first-child {
  margin-top: 0;
}
@media (max-width: 599px) {
  .presskit-container .presskit-row:first-child {
    margin-top: 48px;
  }
}
.showcase {
  width: 80%;
}
.c-resource-nav {
  width: 20%;
}
.resources-container {
  position: relative;
}
.grid-fixed:after, .grid-fixed:before {
  content: ".";
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  font-size: 0;
  line-height: 0;
  width: 0;
  height: 0;
}
@media handheld and (max-width: 480px), screen and (max-width: 480px), screen and (max-width: 900px) {
  .grid-fixed {
    width: auto;
  }
}
@media handheld and (max-width: 480px), screen and (max-width: 480px), screen and (max-width: 900px) {
  .grid-fixed .c3, .grid-fixed .c8 {
    margin-left: 20px;
    margin-right: 20px;
    float: none;
    display: block;
    width: auto;
  }
}
@media handheld and (max-width: 480px), screen and (max-width: 480px), screen and (max-width: 480px) {
  .grid-fixed .c3, .grid-fixed .c8 {
    margin-left: 0px;
    margin-right: 0px;
    float: none;
    display: block;
    width: auto;
  }
}
@media handheld and (max-width: 900px), screen and (max-width: 900px) {
  /* line 6, ../scss/_responsive.scss */
  .grid-fixed {
    margin: 0 auto;
    *zoom: 1;
  }

  .grid-fixed:after, .grid-fixed:before {
    content: ".";
    clear: both;
    display: block;
    overflow: hidden;
    visibility: hidden;
    font-size: 0;
    line-height: 0;
    width: 0;
    height: 0;
  }
}
@media handheld and (max-width: 480px), screen and (max-width: 480px) {
  /* line 6, ../scss/_responsive.scss */
  .grid-fixed {
    margin: 0 auto;
    *zoom: 1;
  }

  .grid-fixed:after, .grid-fixed:before {
    content: ".";
    clear: both;
    display: block;
    overflow: hidden;
    visibility: hidden;
    font-size: 0;
    line-height: 0;
    width: 0;
    height: 0;
  }
}
aio-resource-list .shadow-1 {
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 1px 4px 0 rgba(10, 16, 20, 0.37);
}
aio-resource-list .showcase {
  margin-bottom: 48px;
  border-radius: 4px;
}
aio-resource-list .c-resource h4 {
  margin: 0;
  line-height: 24px;
}
aio-resource-list .c-resource p {
  margin: 0;
}
aio-resource-list .c-resource-nav {
  position: fixed;
  top: 142px;
  right: 32px;
  width: 160px;
  z-index: 1;
  background-color: #fff;
  border-radius: 2px;
}
aio-resource-list .c-resource-nav a {
  color: #373E41;
  text-decoration: none;
}
aio-resource-list .c-resource-nav .category {
  padding: 10px 0;
}
aio-resource-list .c-resource-nav .category .category-link {
  display: block;
  margin: 2px 0;
  padding: 3px 14px;
  font-size: 18px !important;
}
aio-resource-list .c-resource-nav .category .category-link:hover {
  background: #edf0f2;
  color: #2B85E7;
}
aio-resource-list .c-resource-nav .subcategory .subcategory-link {
  display: block;
  margin: 2px 0;
  padding: 4px 14px;
}
aio-resource-list .c-resource-nav .subcategory .subcategory-link:hover {
  background: #edf0f2;
  color: #2B85E7;
}
aio-resource-list .h-anchor-offset {
  display: block;
  position: relative;
  top: -20px;
  visibility: hidden;
}
aio-resource-list .l-flex--column {
  display: flex;
  flex-direction: column;
}
aio-resource-list .c-resource-header {
  margin-bottom: 16px;
}
aio-resource-list .c-contribute {
  margin-bottom: 24px;
}
aio-resource-list .c-resource-header h2 {
  margin: 0;
}
aio-resource-list .subcategory-title {
  padding: 16px 23px;
  margin: 0;
  background-color: #ECEFF1;
  color: #373E41;
}
aio-resource-list .h-capitalize {
  text-transform: capitalize;
}
aio-resource-list .h-hide {
  display: none;
}
aio-resource-list .resource-row-link {
  color: #1a2326;
  border: transparent solid 1px;
  margin: 0;
  padding: 16px 23px 16px 23px;
  position: relative;
  text-decoration: none;
  transition: all 0.3s;
}
aio-resource-list .resource-row-link:hover {
  color: #1a2326;
  text-decoration: none;
  border-color: #2B85E7;
  border-radius: 4px;
  box-shadow: 0 8px 8px rgba(1, 67, 163, 0.24), 0 0 8px rgba(1, 67, 163, 0.12), 0 6px 18px rgba(43, 133, 231, 0.12);
  transform: translateY(-2px);
}
@media (max-width: 900px) {
  aio-resource-list .c-resource-nav {
    display: none;
  }
}
body::-webkit-scrollbar, mat-sidenav.sidenav::-webkit-scrollbar, .mat-sidenav-content::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
body::-webkit-scrollbar-track, mat-sidenav.sidenav::-webkit-scrollbar-track, .mat-sidenav-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
body::-webkit-scrollbar-thumb, mat-sidenav.sidenav::-webkit-scrollbar-thumb, .mat-sidenav-content::-webkit-scrollbar-thumb {
  background-color: #6e6e6e;
  outline: 1px solid #333;
}
.search-results::-webkit-scrollbar, .toc-container::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
.search-results::-webkit-scrollbar-track, .toc-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.search-results::-webkit-scrollbar-thumb, .toc-container::-webkit-scrollbar-thumb {
  background-color: #6e6e6e;
  outline: 1px solid slategrey;
}
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
aio-search-results {
  z-index: 10;
}
.search-results {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  overflow: auto;
  padding: 68px 32px 0;
  color: #FAFAFA;
  width: auto;
  max-height: 95vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background-color: #333;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
}
@media (max-width: 480px) {
  .search-results {
    display: block;
  }
  .search-results .search-area {
    display: block;
    margin: 16px 16px;
  }
}
aio-search-results.embedded .search-results {
  padding: 0;
  color: inherit;
  width: auto;
  max-height: 100%;
  position: relative;
  background-color: inherit;
  box-shadow: none;
  box-sizing: border-box;
}
aio-search-results.embedded .search-results .search-area a {
  color: #4d4d4d;
}
aio-search-results.embedded .search-results .search-area a:hover {
  color: #1E88E5;
}
.search-area {
  display: flex;
  flex-direction: column;
  margin: 16px 16px;
  height: 100%;
}
.search-area h3 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  margin: 10px 0px 5px;
  text-transform: uppercase;
}
.search-area ul {
  margin: 0;
  padding: 0;
}
.search-area ul li {
  list-style: none;
}
.search-area a {
  font-size: 16px;
  line-height: 24px;
  color: #DBDBDB;
  text-decoration: none;
  font-weight: normal;
}
.search-area a:hover {
  color: #FFFFFF;
}
.search-area a:visited {
  text-decoration: none;
}
.search-area a span.symbol {
  margin-right: 8px;
}
.search-area .priority-pages {
  padding: 0.5rem 0;
}
.search-area .priority-pages a {
  font-weight: bold;
}
@media (min-width: 600px) {
  .search-area {
    display: block;
  }
}
.toc-container {
  width: 18%;
  position: fixed;
  top: 76px;
  right: 0;
  bottom: 12px;
  overflow-y: auto;
  overflow-x: hidden;
}
@media (min-width: 801px) {
  aio-toc.embedded {
    display: none;
  }
}
aio-toc.embedded .toc-inner {
  padding: 12px 0 0 0;
}
aio-toc.embedded .toc-inner .toc-heading {
  margin: 0 0 8px;
}
.toc-inner {
  font-size: 13px;
  overflow-y: visible;
  padding: 4px 0 0 10px;
}
.toc-inner .toc-heading,
.toc-inner .toc-list .h1 {
  font-size: 115%;
}
.toc-inner .toc-heading {
  font-weight: 500;
  margin: 0 0 16px 8px;
  padding: 0;
}
.toc-inner .toc-heading.secondary {
  position: relative;
  top: -8px;
}
.toc-inner .toc-heading.secondary:hover {
  color: #1E88E5;
}
.toc-inner button.toc-heading,
.toc-inner button.toc-more-items {
  cursor: pointer;
  display: inline-block;
  background: 0;
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  text-align: start;
}
.toc-inner button.toc-heading.embedded:focus,
.toc-inner button.toc-more-items.embedded:focus {
  outline: none;
  background: #DBDBDB;
}
.toc-inner button.toc-heading mat-icon.rotating-icon {
  height: 18px;
  width: 18px;
  position: relative;
  left: -4px;
  top: 5px;
}
.toc-inner button.toc-heading:hover:not(.embedded) {
  color: #1E88E5;
}
.toc-inner button.toc-more-items {
  color: #6e6e6e;
  top: 10px;
  position: relative;
}
.toc-inner button.toc-more-items:hover {
  color: #1E88E5;
}
.toc-inner button.toc-more-items::after {
  content: "expand_less";
}
.toc-inner button.toc-more-items.collapsed::after {
  content: "more_horiz";
}
.toc-inner .mat-icon.collapsed {
  transform: rotate(0deg);
  transition: transform 150ms;
  transition-timing-function: ease-in-out;
}
.toc-inner .mat-icon:not(.collapsed) {
  transform: rotate(90deg);
  transition: transform 150ms;
  transition-timing-function: ease-in-out;
}
.toc-inner ul.toc-list {
  list-style-type: none;
  margin: 0;
  padding: 0 8px 0 0;
}
@media (max-width: 800px) {
  .toc-inner ul.toc-list {
    width: auto;
  }
}
.toc-inner ul.toc-list li {
  box-sizing: border-box;
  font-size: 12px;
  line-height: 16px;
  padding: 3px 0 3px 12px;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.toc-inner ul.toc-list li.h1:after {
  content: "";
  display: block;
  height: 1px;
  width: 40%;
  margin: 7px 0 4px 0;
  background: #DBDBDB;
  clear: both;
}
.toc-inner ul.toc-list li.h3 {
  padding-left: 24px;
}
.toc-inner ul.toc-list li a {
  font-size: inherit;
  color: #4d4d4d;
  display: table-cell;
  overflow: visible;
  font-size: 12px;
  display: table-cell;
}
.toc-inner ul.toc-list li:hover a {
  color: #1E88E5;
}
.toc-inner ul.toc-list li.active a {
  color: #412846;
  font-weight: 500;
}
.toc-inner ul.toc-list li.active a:before {
  content: "";
  border-radius: 50%;
  left: -3px;
  top: 12px;
  background: #412846;
  position: absolute;
  width: 6px;
  height: 6px;
}
.toc-inner ul.toc-list:not(.embedded) li:before {
  border-left: 1px solid #DBDBDB;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
}
.toc-inner ul.toc-list:not(.embedded) li:first-child:before {
  top: 13px;
}
.toc-inner ul.toc-list:not(.embedded) li:last-child:before {
  bottom: calc(100% - 14px);
}
.toc-inner ul.toc-list:not(.embedded) li:not(.active):hover a:before {
  content: "";
  border-radius: 50%;
  left: -3px;
  top: 12px;
  background: #DBDBDB;
  position: absolute;
  width: 6px;
  height: 6px;
}
aio-toc.embedded > div.collapsed li.secondary {
  display: none;
}
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
/* SELECT MENU */
.form-select-menu {
  position: relative;
}
.form-select-button {
  background: #FFFFFF;
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
  box-sizing: border-box;
  border: 1px solid #FFFFFF;
  color: #546E7A;
  font-size: 14px;
  line-height: 20px;
  line-height: 32px;
  font-weight: 400;
  height: 32px;
  outline: none;
  padding: 0 16px;
  text-align: left;
  width: 100%;
  cursor: pointer;
}
.form-select-button strong {
  font-weight: 600;
  margin-right: 8px;
  text-transform: uppercase;
}
.form-select-button:focus {
  border: 1px solid #42A5F5;
  box-shadow: 0 2px 2px rgba(66, 165, 245, 0.24), 0 0 2px rgba(66, 165, 245, 0.12);
}
.form-select-dropdown {
  background: #FFFFFF;
  box-shadow: 0 16px 16px rgba(10, 16, 20, 0.24), 0 0 16px rgba(10, 16, 20, 0.12);
  border-radius: 4px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
.form-select-dropdown li {
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  line-height: 32px;
  margin: 0;
  padding: 0 16px 0 40px;
  position: relative;
  transition: all 0.2s;
}
.form-select-dropdown li:hover {
  background: #ECEFF1;
  color: #2196F3;
}
.form-select-dropdown li.selected {
  background-color: #CFD8DC;
}
.form-select-dropdown li .symbol {
  left: 16px;
  position: absolute;
  top: 8px;
  z-index: 5;
}
aio-shell.mode-archive .mat-toolbar.mat-primary, aio-shell.mode-archive footer {
  background: linear-gradient(145deg, #263238, #78909C);
}
aio-shell.mode-archive .vertical-menu-item.selected, aio-shell.mode-archive .vertical-menu-item:hover {
  color: #263238;
}
aio-shell.mode-archive .toc-inner ul.toc-list li.active a {
  color: #263238;
}
aio-shell.mode-archive .toc-inner ul.toc-list li.active a:before {
  background-color: #263238;
}
aio-shell.mode-archive .toc-inner ul.toc-list li:hover a {
  color: #263238;
}
aio-shell.mode-next footer {
  background: linear-gradient(145deg, #84438a, #84438a);
}
aio-shell.mode-next .vertical-menu-item.selected, aio-shell.mode-next .vertical-menu-item:hover {
  color: #84438a;
}
aio-shell.mode-next .toc-inner ul.toc-list li.active a {
  color: #84438a;
}
aio-shell.mode-next .toc-inner ul.toc-list li.active a:before {
  background-color: #84438a;
}
aio-shell.mode-next .toc-inner ul.toc-list li:hover a {
  color: #84438a;
}
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
.mat-toolbar mat-toolbar-row.notification-container {
  padding: 0;
  height: auto;
  overflow: hidden;
}
aio-notification {
  background: #412846;
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: 56px;
  justify-content: center;
}
@media (max-width: 430px) {
  aio-notification {
    justify-content: flex-start;
    padding-left: 10px;
  }
}
aio-notification .close-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 56px;
  height: 56px;
  background: #333;
}
aio-notification .content {
  max-width: calc(100% - 56px);
  text-transform: none;
  padding: 0;
}
aio-notification .content > * {
  display: flex;
}
aio-notification .content .icon {
  margin-right: 10px;
}
@media (max-width: 464px) {
  aio-notification .content .icon {
    display: none;
  }
}
aio-notification .content .message {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  color: #FAFAFA;
}
aio-notification .content .action-button {
  background: #a829c3;
  color: #FAFAFA;
  border-radius: 15px;
  text-transform: uppercase;
  padding: 0 10px;
  font-size: 14px;
  line-height: 20px;
}
@media (max-width: 780px) {
  aio-notification .content .action-button {
    display: none;
  }
}
.aio-notification-show .sidenav-content {
  padding-top: 136px;
}
.aio-notification-show mat-sidenav.mat-sidenav.sidenav {
  top: 112px;
}
@media (max-width: 600px) {
  .aio-notification-show mat-sidenav.mat-sidenav.sidenav {
    top: 112px;
  }
}
.aio-notification-show .toc-container {
  top: 132px;
}
.aio-notification-show .search-results {
  padding-top: 124px;
}
.aio-notification-show.page-home section, .aio-notification-show.page-resources section, .aio-notification-show.page-events section, .aio-notification-show.page-features section, .aio-notification-show.page-presskit section, .aio-notification-show.page-contribute section {
  padding-top: 56px;
}
.aio-notification-animating .sidenav-content {
  transition: padding-top 250ms ease;
}
.aio-notification-animating mat-sidenav.mat-sidenav.sidenav, .aio-notification-animating .toc-container {
  transition: top 250ms ease;
}
/************************************

             Media queries

 To use these, put this snippet in the appropriate selector:

     @include bp(tiny) {
         background-color: purple;
     }

     Replace "tiny" with "medium" or "big" as necessary.
*************************************/
label.raised, .api-header label {
  border-radius: 4px;
  padding: 4px 16px;
  display: inline;
  font-size: 16px;
  line-height: 24px;
  color: white;
  margin-right: 8px;
  font-weight: 500;
  text-transform: uppercase;
}
@media screen and (max-width: 600px) {
  label.raised, .api-header label {
    display: block;
    margin: 8px 0;
  }
}
label.raised.page-label, .api-header label.page-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ECEFF1;
  color: #6e6e6e;
  margin-bottom: 8px;
  width: 140px;
}
label.raised.page-label .material-icons, .api-header label.page-label .material-icons {
  margin-right: 8px;
}
label.raised.property-type-label, .api-header label.property-type-label {
  font-size: 14px;
  line-height: 20px;
  background-color: #333;
  color: #FFFFFF;
  text-transform: none;
}
.api-header label {
  padding: 2px 10px;
  font-size: 14px;
  line-height: 20px;
}
@media screen and (max-width: 600px) {
  .api-header label {
    margin: 4px 0;
  }
}
.api-header label.api-status-label {
  background-color: #6e6e6e;
}
.api-header label.api-status-label.impure-pipe {
  background-color: #DD0031;
}
.api-header label.api-type-label {
  background-color: #1E88E5;
}
.api-header label.api-type-label.all {
  background: #FFFFFF;
}
.api-header label.api-type-label.decorator {
  background: #1565C0;
}
.api-header label.api-type-label.directive {
  background: #D81B60;
}
.api-header label.api-type-label.pipe {
  background: #546E7A;
}
.api-header label.api-type-label.class {
  background: #2196F3;
}
.api-header label.api-type-label.interface {
  background: #009688;
}
.api-header label.api-type-label.function {
  background: #4CAF50;
}
.api-header label.api-type-label.enum {
  background: #FFA000;
}
.api-header label.api-type-label.const {
  background: #6e6e6e;
}
.api-header label.api-type-label.let {
  background: #6e6e6e;
}
.api-header label.api-type-label.var {
  background: #6e6e6e;
}
.api-header label.api-type-label.ngmodule {
  background: #940;
}
.api-header label.api-type-label.type-alias {
  background: #7CB342;
}
.api-header label.api-type-label.package {
  background: #8E24AA;
}
@media print {
  * {
    box-shadow: none !important;
  }

  body, mat-sidenav-container {
    background: none !important;
  }

  h1 {
    height: 40px !important;
    color: #333 !important;
  }

  h1, h2, h3, h4, h5, h6 {
    page-break-after: avoid;
  }

  ul, ol, img, code-example, table, tr, .alert, .callout, .feature {
    page-break-inside: avoid;
  }

  table tbody tr:last-child td {
    border-bottom: 1px solid #DBDBDB !important;
  }

  img {
    max-width: 100% !important;
  }

  p {
    widows: 4;
  }

  p > code, li > code, table code {
    color: #412846 !important;
  }

  .no-print {
    display: none !important;
  }

  mat-sidenav.sidenav.mat-sidenav {
    display: none !important;
  }

  .mat-sidenav-content {
    margin: 0 !important;
  }

  mat-sidenav-container.sidenav-container {
    min-width: 100vw;
  }

  .sidenav-content {
    overflow: visible;
  }

  .filetree {
    max-width: 100%;
  }

  aio-code code {
    border: none !important;
  }

  code-example.code-shell, code-example[language=sh], code-example[language=bash] {
    background: none;
  }
  code-example.code-shell .pnk, code-example.code-shell .blk, code-example.code-shell .pln, code-example.code-shell .otl, code-example.code-shell .kwd, code-example.code-shell .typ, code-example.code-shell .tag, code-example.code-shell .str, code-example.code-shell .atv, code-example.code-shell .atn, code-example.code-shell .com, code-example.code-shell .lit, code-example.code-shell .pun, code-example.code-shell .dec, code-example[language=sh] .pnk, code-example[language=sh] .blk, code-example[language=sh] .pln, code-example[language=sh] .otl, code-example[language=sh] .kwd, code-example[language=sh] .typ, code-example[language=sh] .tag, code-example[language=sh] .str, code-example[language=sh] .atv, code-example[language=sh] .atn, code-example[language=sh] .com, code-example[language=sh] .lit, code-example[language=sh] .pun, code-example[language=sh] .dec, code-example[language=bash] .pnk, code-example[language=bash] .blk, code-example[language=bash] .pln, code-example[language=bash] .otl, code-example[language=bash] .kwd, code-example[language=bash] .typ, code-example[language=bash] .tag, code-example[language=bash] .str, code-example[language=bash] .atv, code-example[language=bash] .atn, code-example[language=bash] .com, code-example[language=bash] .lit, code-example[language=bash] .pun, code-example[language=bash] .dec {
    color: #333;
  }
  code-example header {
    background: none;
    border: 0.5px solid #DBDBDB;
    color: #333;
  }

  .content code {
    border: 0.5px solid #DBDBDB;
  }

  .mat-tab-labels div.mat-tab-label:not(.mat-tab-label-active) span {
    font-style: italic;
  }
  .mat-tab-labels div.mat-tab-label.mat-tab-label-active span {
    font-weight: bold;
  }

  .api-header label {
    color: #333 !important;
    font-weight: bold !important;
    margin: 2px !important;
    padding: 0 !important;
    display: block !important;
  }

  .feature-section img {
    max-width: 70px !important;
  }
}
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}
* {
  box-sizing: border-box;
}
